import { useEffect, useState } from "react";

import { db } from "@/services/firebase.js";

import { doc, onSnapshot, setDoc } from "firebase/firestore";

import { signal, effect } from "@preact/signals-core";
import { LightspeedConfigSchema, SASSystemConfigSchema } from "@snopro/common/config-models.js";

import { firebaseUserSignal } from "@/contexts/AuthContext.jsx";

import { initalAnyValuedAsUndefined } from "./initialize-states.js";

/**
 * @type {SAS2.SystemConfig.TSASSystemConfig}
 */
const initSASSystemConfig = {};

export const sasSystemConfigSignal = signal(initSASSystemConfig);

export const useSASSystemConfig = () => {
  const [SASSystemConfig, setSASSystemConfig] = useState(sasSystemConfigSignal.value);
  useEffect(() => {
    return effect(() => {
      setSASSystemConfig(sasSystemConfigSignal.value);
    });
  }, []);
  return { SASSystemConfig };
};

const LightspeedConfigPartialSchema = LightspeedConfigSchema.partial({
  apiToken: true,
  apiUrl: true,
  register_id: true,
  rentalSales_product_id: true,
  skipass_product_id: true,
  tax_id: true,
  user_id: true,
});

/** @typedef {import("zod").infer<typeof LightspeedConfigPartialSchema>} TLightspeedConfig */

/** @type {TLightspeedConfig} */
const initLightspeedConfig = {
  register_id: "",
  user_id: "",
  skipass_product_id: "",
  rentalSales_product_id: "",
  tax_id: "",
  apiUrl: "",
  apiToken: "",
};
export const useLightSpeedConfig = () => {
  const [lightspeedConfig, setLightspeedConfig] = useState(initLightspeedConfig);

  const [error, setError] = useState(initalAnyValuedAsUndefined());

  useEffect(() => {
    setError(undefined);
    const unsub = onSnapshot(doc(db, "system-config", "lightspeed"), (doc) => {
      try {
        setError(undefined);
        if (!doc.exists()) {
          setLightspeedConfig(initLightspeedConfig);
          return;
        }
        const data = doc.data();
        setLightspeedConfig(LightspeedConfigPartialSchema.parse(data));
      } catch (error) {
        setError(error);
      }
    });
    return unsub;
  }, []);
  return { lightspeedConfig, error };
};

let unsubscribe;
effect(() => {
  if (firebaseUserSignal.value) {
    unsubscribe = onSnapshot(
      doc(db, "data", "system-config"),
      (snapshot) => {
        if (!snapshot.exists()) {
          const docRef = doc(db, "data", "system-config");
          setDoc(docRef, initSASSystemConfig);
          sasSystemConfigSignal.value = initSASSystemConfig;
          return;
        }
        const data = snapshot.data();
        try {
          sasSystemConfigSignal.value = SASSystemConfigSchema.parse(data);
        } catch (error) {
          console.error(data);
          console.error("Error parsing system config", error);
        }
      },
      (error) => {
        console.error("Error getting system config", error);
        unsubscribe = undefined;
      },
    );
  } else {
    if (unsubscribe) {
      unsubscribe();
      unsubscribe = undefined;
    }
  }
});
