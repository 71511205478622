/// <reference types="vite/client" />
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore, Timestamp } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";

import { init } from "@snopro/common/firestore.js";

init({
  getAsDate: (obj) => (obj instanceof Timestamp || obj?.toDate ? obj.toDate() : undefined),
});

if (!import.meta.env.VITE_APP_SAS2_FIREBASE_CONFIG) {
  throw new Error("VITE_APP_SAS2_FIREBASE_CONFIG is not defined");
}
const firebaseConfig = JSON.parse(import.meta.env.VITE_APP_SAS2_FIREBASE_CONFIG);

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app, "australia-southeast1");
const __PUBLIC_BUCKET_NAME =
  import.meta.env.VITE_APP_ENV === "prod" ? "sas2-prod-public" : "sas2-test-public";

if (location.hostname === "localhost" && import.meta.env.VITE_APP_LOCAL_FIRESTORE === "true") {
  connectFirestoreEmulator(db, "127.0.0.1", 8080);
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
  connectStorageEmulator(storage, "127.0.0.1", 9199);
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}

export { auth, db, storage, functions, app, __PUBLIC_BUCKET_NAME };
