import { Box, Text, VStack, useDisclosure } from "@chakra-ui/react";

import dayjs from "dayjs";

import ChangeDateTimeModal from "../modals/ChangeDateTimeModal.jsx";

/**
 *
 * @param {Object} props
 * @param {Date|dayjs.Dayjs} props.date
 * @param {boolean} [props.includeTime]
 * @param {keyof TOrder} [props.updateField]
 * @param {TIOrder} [props.order]
 * @param {boolean} [props.highlightWeekends]
 * @param {Date} [props.dateRangeStart]
 * @param {Date} [props.dateRangeEnd]
 * @param {boolean} [props.isCompact]
 * @returns {JSX.Element}
 */
export default function DateBox({
  date,
  includeTime,
  highlightWeekends,
  dateRangeStart,
  dateRangeEnd,
  order,
  updateField,
  isCompact,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box
        as={order ? "button" : undefined}
        borderWidth={1}
        borderColor={
          (dateRangeEnd && date > dateRangeEnd) || (dateRangeStart && date < dateRangeStart)
            ? "gray.200"
            : "black"
        }
        color={
          (dateRangeEnd && date > dateRangeEnd) || (dateRangeStart && date < dateRangeStart)
            ? "gray.200"
            : "black"
        }
        bgColor={
          dayjs.tz(date).isToday()
            ? "brand.500"
            : highlightWeekends &&
              (dayjs.tz(date).format("ddd") == "Sat" || dayjs.tz(date).format("ddd") == "Sun")
            ? "gray.100"
            : ""
        }
        px="2"
        w={isCompact ? "25px" : "50px"}
        p={0}
        borderRadius={4}
        onClick={onOpen}
      >
        <VStack width="auto">
          <Text fontSize={8} fontWeight="bold">
            {dayjs.tz(date).format("ddd").toUpperCase()}
          </Text>
          <Text as="span" fontSize={isCompact ? 16 : 24} fontWeight="bold" lineHeight={0}>
            {dayjs.tz(date).format("D")}
          </Text>
          <Text fontSize={8} fontWeight="bold">
            {dayjs.tz(date).format("MMM").toUpperCase()}
          </Text>
          {includeTime && (
            <Box bgColor="black" borderColor="black" width="100%" textAlign="center" mt="0px!">
              <Text color="white" fontSize={16} fontWeight="bold" lineHeight={1} pt={1}>
                {dayjs.tz(date).format("h:mm")}
              </Text>
              <Text color="white" fontSize={10} fontWeight="bold" lineHeight={1} pb={1}>
                {dayjs.tz(date).format("a")}
              </Text>
            </Box>
          )}
        </VStack>
      </Box>

      {order && isOpen && (
        <ChangeDateTimeModal
          isOpen={isOpen}
          onClose={onClose}
          date={date}
          order={order}
          updateField={updateField}
        />
      )}
    </>
  );
}
