import { useEffect, useMemo, useState } from "react";

import { db } from "@/services/firebase.js";

import {
  arrayUnion,
  collection,
  deleteField,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  where,
  writeBatch,
} from "firebase/firestore";

import { AllocationSchema, HardwareSchema } from "@snopro/common/models.js";
import { orderStateToStatusDescription } from "@snopro/common/order-utils.js";
import { plural } from "@snopro/common/string-utils.js";
import dayjs from "dayjs";

import { getLoggedInUser } from "@/contexts/AuthContext.jsx";
import { fetchOrderById } from "@/hooks/orderHooks.js";
import { saveActivity } from "@/services/activityLog.js";

import { useShowError } from "./error.js";
import { initHardwareList, initSetString } from "./initialize-states.js";
import { updateOrder } from "./order.api.js";
import { updateSkier } from "./skier.api.js";

/**
 * @type {Record<TPackageType, number>}
 */
const __packageTypeOrder = {
  "Progression Ski": 1,
  "Performer Ski": 2,
  "Premier Ski": 3,
  "Junior Ski": 4,
  "Tweener Ski": 5,
  "Performer Snowboard": 6,
  "Premier Snowboard": 7,
  "Junior Snowboard": 8,
};

/**
 * @type {TPackageType[]}
 */
const __packageTypes = [
  "Progression Ski",
  "Performer Ski",
  "Premier Ski",
  "Junior Ski",
  "Tweener Ski",
  "Performer Snowboard",
  "Premier Snowboard",
  "Junior Snowboard",
];
__packageTypes.sort((l, r) => __packageTypeOrder[l] - __packageTypeOrder[r]);

/**
 * @param {THardware} l
 * @param {THardware} r
 * @returns {number}
 */
export const compareHardware = (l, r) => {
  const lOrder = __packageTypeOrder[l.package] ?? Infinity;
  const rOrder = __packageTypeOrder[r.package] ?? Infinity;
  let result = lOrder - rOrder;
  if (result === 0) {
    result = l.package.localeCompare(r.package);
  }
  if (result === 0) {
    result = l.length - r.length;
  }
  if (result === 0) {
    result = l.code.localeCompare(r.code);
  }
  return result;
};

/**
 * @param {string} id
 * @returns {Promise<THardware>}
 */
export const fetchHardwareById = async (id) => {
  const hardwareDocto = await getDoc(doc(db, "hardware", id));
  if (!hardwareDocto.exists) {
    throw new Error(`Hardware with id ${id} not found`);
  }
  return HardwareSchema.parse({ ...hardwareDocto.data(), id: hardwareDocto.id });
};

/**
 * @param {string} code
 * @param {string} [hardwareId]
 * @returns {Promise<boolean>}
 */
export const isHardwareCodeTaken = async (code, hardwareId) => {
  const listSameCode = await getDocs(
    query(collection(db, "hardware"), where("isActive", "==", true), where("code", "==", code)),
  );

  for (const doc of listSameCode.docs) {
    if (hardwareId && doc.id === hardwareId) {
      continue;
    }

    return true;
  }
  return false;
};
/**
 * @param {string} hardwareId
 * @param {(hardware:THardware) => Promise<void> } validate
 * @param {THardwareStatus} newStatus
 * @param {SAS2.firebase.FirestoreFieldPatch<Omit<THardware, "status"|"statusHistory"|"updateTimestamp"|"id">>} [update]
 * @param {import("firebase/firestore").WriteBatch} [firestoreBatch]
 * @returns {Promise<{beforeUpdate:THardware}>}
 */
const updateHardwareStatus = async (hardwareId, validate, newStatus, update, firestoreBatch) => {
  const batch = firestoreBatch || writeBatch(db);
  const user = getLoggedInUser();
  const hardware = await fetchHardwareById(hardwareId);
  await validate(hardware);

  /** @type {SAS2.firebase.FirestoreNewObject<THardwareStatusHistory>} */
  const statusHistory = {
    newStatus,
    timestamp: new Date(), // unfortunately, we can't use serverTimestamp() in the array unions
    previousStatus: hardware.status,
    user: {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
    },
    ...(update && "notes" in update ? { notes: update.notes ?? "" } : undefined),
  };

  /** @type {SAS2.firebase.FirestoreFieldPatch<THardware>} */
  const updatePayload = {
    ...update,
    statusHistory: arrayUnion(statusHistory),
    status: newStatus,
    updateTimestamp: serverTimestamp(),
  };
  const hardwareDocto = doc(db, "hardware", hardwareId);
  batch.update(hardwareDocto, updatePayload);
  if (!firestoreBatch) {
    await batch.commit();
  }
  return { beforeUpdate: hardware };
};
/**
 * @param {string} hardwareId
 * @returns {Promise<void>}
 */
export const makeHardwareAvailable = async (hardwareId) => {
  const batch = writeBatch(db);
  const { beforeUpdate } = await updateHardwareStatus(
    hardwareId,
    async (hardware) => {
      if (hardware.status !== "Unavailable") {
        throw new Error("Only unavailable hardware can be made available");
      }
    },
    "Available",
    undefined,
    batch,
  );
  await saveActivity(
    "hardware-available",
    `${beforeUpdate.code} made available by ${getLoggedInUser().firstName}`,
    undefined,
    undefined,
    undefined,
    undefined,
    batch,
  );
  await batch.commit();
};

/**
 * @param {string} hardwareId
 * @param {string} [notes]
 * @returns {Promise<void>}
 */
export const makeHardwareUnavailable = async (hardwareId, notes) => {
  const batch = writeBatch(db);
  const { beforeUpdate } = await updateHardwareStatus(
    hardwareId,
    async (hardware) => {
      if (hardware.status === "Unavailable") {
        throw new Error("Hardware is already unavailable");
      }
      if (hardware.status === "Disposed") {
        throw new Error("Hardware is disposed");
      }
    },
    "Unavailable",
    { notes },
    batch,
  );
  await saveActivity(
    "hardware-unavailable",
    `${beforeUpdate.code} made unavailable by ${getLoggedInUser().firstName}`,
    undefined,
    undefined,
    undefined,
    undefined,
    batch,
  );
  await batch.commit();
};

/**
 * @param {string} hardwareId
 * @param {string} [notes]
 * @returns {Promise<void>}
 */
export const disposeHardware = async (hardwareId, notes) => {
  const batch = writeBatch(db);
  const { beforeUpdate } = await updateHardwareStatus(
    hardwareId,
    async (hardware) => {
      if (hardware.status === "Disposed") {
        throw new Error("Hardware is already disposed");
      }
    },
    "Disposed",
    { notes, isActive: false },
    batch,
  );

  const initialDate = dayjs.tz().startOf("day").toDate();
  const allAlocations = await getDocs(
    query(
      collection(db, "allocations"),
      where("hardwareId", "==", hardwareId),
      where("start", ">=", initialDate),
    ),
  );
  const orderIds = new Set(initSetString());

  for (const docto of allAlocations.docs) {
    batch.delete(docto.ref);
    const allocation = AllocationSchema.parse({ ...docto.data(), id: docto.id });
    if (!allocation.orderId || !allocation.skierId) {
      continue;
    }

    if (!orderIds.has(allocation.orderId)) {
      orderIds.add(allocation.orderId);
      const order = await fetchOrderById(allocation.orderId);
      if (!order) {
        continue;
      }

      if (order.state !== "toSize") {
        if (order.state !== "toPack" && order.state !== "toDeliver") {
          throw new Error(
            `Cannot dispose hardware because of the order ${order.lookupId} - ${order.firstName} ${
              order.lastName
            } is in ${orderStateToStatusDescription(order.state)}`,
          );
        }
        await updateOrder(order, { state: "toSize" }, "gear-page", batch);
      }
    }

    await updateSkier(
      allocation.orderId,
      allocation.skierId,
      { selectedHardware: deleteField() },
      "gear-page",
      batch,
    );
  }

  await saveActivity(
    "hardware-disposed",
    `${beforeUpdate.code} disposed by ${getLoggedInUser().firstName}`,
    undefined,
    undefined,
    undefined,
    undefined,
    batch,
  );

  await batch.commit();
};

/**
 * @param {string} hardwareId
 * @returns {Promise<void>}
 */
export const undoDisposeHardware = async (hardwareId) => {
  const batch = writeBatch(db);
  const { beforeUpdate } = await updateHardwareStatus(
    hardwareId,
    async (hardware) => {
      if (hardware.status !== "Disposed") {
        throw new Error("Hardware is not disposed");
      }
      const hasDuplicated = await isHardwareCodeTaken(hardware.code, hardwareId);
      if (hasDuplicated) {
        throw new Error("There is another active hardware with the same code");
      }
    },
    "Unavailable",
    { isActive: true },
    batch,
  );
  await saveActivity(
    "hardware-undisposed",
    `${beforeUpdate.code} undisposed by ${getLoggedInUser().firstName}`,
    undefined,
    undefined,
    undefined,
    undefined,
    batch,
  );

  await batch.commit();
};

export async function deleteHardware(hardwareId) {
  const allocationsCount = await getCountFromServer(
    query(collection(db, "allocations"), where("hardwareId", "==", hardwareId)),
  );
  const count = allocationsCount.data().count;
  if (count > 0) {
    throw new Error(`Hardware has been allocated ${plural("time", count)} and cannot be deleted.`);
  }
  const hardware = await fetchHardwareById(hardwareId);

  const deletedRef = doc(db, "deleted-hardware", hardwareId);
  const hardwareRef = doc(db, "hardware", hardwareId);
  const batch = writeBatch(db);
  batch.delete(hardwareRef);
  batch.set(deletedRef, {
    ...hardware,
    deletedTimestamp: serverTimestamp(),
    deletedBy: getLoggedInUser(),
  });
  await saveActivity(
    "hardware-deleted",
    `${hardware.code} deleted by ${getLoggedInUser().firstName}`,
    undefined,
    undefined,
    undefined,
    undefined,
    batch,
  );
  await batch.commit();
}

export const useHardwareTypes = () => {
  const packageTypes = useMemo(() => Array.from(__packageTypes), []);

  return { packageTypes };
};

/**
 * @param {object} opts
 * @param {TLocation} [opts.location]
 * @param {THardwareStatus} [opts.status]
 * @param {TPackageType[]} [opts.packageTypes]
 */
export function useHardwareList({ location, status, packageTypes }) {
  const { showError } = useShowError();

  const [hardwareList, setHardwareList] = useState(initHardwareList());
  useEffect(() => {
    if (!location) {
      setHardwareList(initHardwareList());
      return;
    }
    const constraints = [where("location", "==", location)];
    if (!status) {
      constraints.push(where("isActive", "==", true));
      if (
        packageTypes &&
        packageTypes.length > 0 &&
        packageTypes.length !== __packageTypes.length
      ) {
        constraints.push(where("package", "in", packageTypes));
      }
    } else {
      constraints.push(where("status", "==", status));
    }

    const q = query(collection(db, "hardware"), ...constraints, orderBy("code", "asc"));

    const unsub = onSnapshot(
      q,
      (querySnapshot) => {
        const list = initHardwareList();
        querySnapshot.docs.forEach((doc) => {
          const parsed = HardwareSchema.safeParse({ ...doc.data(), id: doc.id });
          if (parsed.success) {
            list.push(parsed.data);
            return;
          }
          console.error(parsed.error.formErrors, doc.data());
          throw new Error("Failed to parse hardware");
        });
        setHardwareList(list);
      },
      showError,
    );
    return unsub;
  }, [location, status, packageTypes]);
  return { hardwareList };
}
