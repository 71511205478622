import { useEffect, useState } from "react";

import { db } from "@/services/firebase.js";

import { doc, getDoc, onSnapshot } from "firebase/firestore";

import { signal, effect } from "@preact/signals-core";
import { ConfigDataSchema } from "@snopro/common/models.js";

import { firebaseUserSignal } from "@/contexts/AuthContext.jsx";

/** @returns {TConfigData|undefined} */
const initConfigData = () => undefined;

export const configDataSignal = signal(initConfigData());

/**
 * @type {Promise<TConfigData>|null}
 */
let __ConfigDataCache = null;

export const getConfigData = async () => {
  if (!__ConfigDataCache) {
    __ConfigDataCache = getDoc(doc(db, "data", "config")).then((doctoSnapshot) => {
      if (!doctoSnapshot.exists()) {
        throw new Error("Config data not found");
      }
      const parsed = ConfigDataSchema.safeParse(doctoSnapshot.data());
      if (!parsed.success) {
        console.error(parsed.error.flatten().fieldErrors);
        throw new Error("Config data is invalid");
      }
      return parsed.data;
    });
  }
  return __ConfigDataCache;
};

export const getLatestConfigData = async () => {
  if (configDataSignal.value) {
    return configDataSignal.value;
  }

  if (!__ConfigDataCache) {
    __ConfigDataCache = getDoc(doc(db, "data", "config")).then((doctoSnapshot) => {
      if (!doctoSnapshot.exists()) {
        throw new Error("Config data not found");
      }
      const parsed = ConfigDataSchema.safeParse(doctoSnapshot.data());
      if (!parsed.success) {
        console.error(parsed.error.flatten().fieldErrors);
        throw new Error("Config data is invalid");
      }
      return parsed.data;
    });
  }
  return __ConfigDataCache;
};

let unsubscribe;
effect(() => {
  if (firebaseUserSignal.value) {
    unsubscribe = onSnapshot(
      doc(db, "data", "config"),
      (snapshot) => {
        if (!snapshot.exists()) {
          console.error("Config data not found");
          return;
        }

        const data = snapshot.data();

        try {
          configDataSignal.value = ConfigDataSchema.parse(data);
          console.log("Config data snapshot executed.", configDataSignal.value);
        } catch (error) {
          console.error(data);
          console.error("Error parsing config data", error);
        }
      },
      (error) => {
        console.error("Error getting config data", error);
        unsubscribe = undefined;
      },
    );
  } else {
    if (unsubscribe) {
      console.log("No user, unsubscribing from config data");
      unsubscribe();
      unsubscribe = undefined;
    }
  }
});

/**
 * @returns {TConfigData}
 */
export const useConfigData = () => {
  const [configData, setConfigData] = useState(configDataSignal.value);

  useEffect(() => {
    return configDataSignal.subscribe((data) => {
      setConfigData(data);
    });
  }, []);
  if (!configData) {
    console.error("Config data is not available");
    throw new Error("Config data is not available");
  }
  return configData;
};
