import { Fragment, useMemo, useState } from "react";

import { HStack, Input, Radio, RadioGroup, VStack, Text, Divider } from "@chakra-ui/react";

import { useConfigData } from "@/lib/configData.jsx";

/**
 * @param {{field: string; direction: "desc"|"asc"}[]} fields
 */
function sortObjectByFields(fields) {
  /** @type {{(a: [string, object], b: [string, object]) : number}} */
  return (l, r) => {
    const [keyL, objL] = l;
    const [keyR, objR] = r;

    for (const { field, direction } of fields) {
      const fieldValueL = objL[field];
      const fieldValueR = objR[field];

      if (fieldValueL < fieldValueR) {
        return direction === "asc" ? -1 : 1;
      }
      if (fieldValueL > fieldValueR) {
        return direction === "asc" ? 1 : -1;
      }
    }
    return keyL.localeCompare(keyR);
  };
}

export default function AddSkiPassList({ addSkiPass, firstSkiDay }) {
  const configData = useConfigData();
  const passes = useMemo(
    () =>
      Object.entries(configData.passes)
        .filter(([, item]) => item.status === "active")
        .sort(
          sortObjectByFields([
            { field: "type", direction: "desc" },
            { field: "group", direction: "asc" },
            { field: "days", direction: "asc" },
          ]),
        )
        .map(([id, item]) => ({
          ...item,
          id,
        })),
    [configData],
  );

  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [firstDay, setFirstDay] = useState(firstSkiDay);
  const filterPasses = useMemo(() => {
    if (selectedGroup && selectedType && birthDay && firstDay) {
      return passes.filter((pass) => pass.group === selectedGroup && pass.type === selectedType);
    }
    return [];
  }, [birthDay, firstDay, passes, selectedGroup, selectedType]);
  return (
    <>
      <VStack spacing={6} m={6} divider={<Divider />}>
        <HStack spacing={12}>
          <VStack>
            <Input
              value={birthDay}
              placeholder="birth date(DD/MM/YYYY)"
              onChange={(e) => setBirthDay(e.target.value)}
            />
            <Text fontSize="xs" fontWeight="bold">
              Birthdate
            </Text>
          </VStack>
          <VStack>
            <Input value={firstDay} onChange={(e) => setFirstDay(e.target.value)} />
            <Text fontSize="xs" fontWeight="bold">
              First ski day
            </Text>
          </VStack>
        </HStack>
        <RadioGroup value={selectedGroup} onChange={setSelectedGroup} colorScheme="brand">
          <HStack direction="row" spacing={6}>
            <Radio value="Adult">
              <VStack spacing={0} align="flex-start">
                <Text>Adult</Text>
                <Text fontSize="xs">18+</Text>
              </VStack>
            </Radio>
            <Radio value="Child">
              <VStack spacing={0} align="flex-start">
                <Text>Child</Text>
                <Text fontSize="xs">6-17</Text>
              </VStack>
            </Radio>
            <Radio value="Student">
              <VStack spacing={0} align="flex-start">
                <Text>Student</Text>
                <Text fontSize="xs">AU/NZ full time</Text>
              </VStack>
            </Radio>
            <Radio value="Senior">
              <VStack spacing={0} align="flex-start">
                <Text>Senior</Text>
                <Text fontSize="xs">65-74</Text>
              </VStack>
            </Radio>
            <Radio value="Free">
              <VStack spacing={0} align="flex-start">
                <Text>Free</Text>
                <Text fontSize="xs">0-5 / 75+</Text>
              </VStack>
            </Radio>
          </HStack>
        </RadioGroup>
        <RadioGroup value={selectedType} onChange={setSelectedType} colorScheme="brand">
          <HStack direction="row" spacing={5}>
            <Radio value="Single">Single Mountain</Radio>
            <Radio value="Dual">Dual Mountain</Radio>
          </HStack>
        </RadioGroup>
      </VStack>
      <Divider />

      {filterPasses.map((pass) => {
        return (
          <Fragment key={pass.id}>
            <Divider />
            <HStack spacing={0} as="button" onClick={() => addSkiPass(pass, birthDay, firstDay)}>
              <Text fontWeight="bold" p={4}>
                {pass.days}
              </Text>
              <Text color="gray.300">${pass.price}</Text>
            </HStack>
          </Fragment>
        );
      })}
    </>
  );
}
