/**
 * @param {number|undefined} [v]
 * @returns {number|undefined}
 * */
export const initNumberOrUndefined = (v) => v;
/**
 * @param {TSkierAdjustmentDayPosition|undefined} value
 * @returns {TSkierAdjustmentDayPosition|undefined}
 * */
export const initAdjustmentDayTypeOrDefault = (value = undefined) => value;

/** @returns {HTMLElement|null} */
export const initElementRef = () => null;
/** @returns {HTMLDivElement|null} */
export const initRefDiv = () => null;
/** @returns {HTMLInputElement|null} */
export const initRefInput = () => null;
/** @returns {HTMLButtonElement|null} */
export const initRefButton = () => null;
/** @returns {HTMLSelectElement|null} */
export const initRefInputSelect = () => null;
/** @returns {HTMLTextAreaElement|null} */
export const initRefTextArea = () => null;

/** @returns {TOrderAndSkiersWithItems|undefined} */
export const initOrderAndSkiersWithItems = () => undefined;

/** @returns {TOrderAndSkiers|undefined} */
export const initOrderAndSkiers = () => undefined;

/** @returns {TOrder[]} */
export const initOrderList = () => [];

/** @returns {TSkier[]} */
export const initSkiers = () => [];

/** @returns {TOrder|undefined} */
export const initialOrderOrUndefined = () => undefined;

/** @returns {TSundriesItem[]} */
export const initialSundriesList = () => [];

/** @returns {TSundriesItem|undefined} */
export const initialSundriesItemOrUndefined = () => undefined;

/**
 * @template T
 * @param {T} defaultValue
 * @returns {TOrderSupportCall|T}
 * */
export const initialSupportCallOrDefault = (defaultValue) => defaultValue;

/** @returns {TOrderSupportCall[]} */
export const initialSupportCalls = () => [];

/** @return {TCalculatedOrder[]} */
export const initCalculatedOrders = () => [];

/** @return {TReferrer[]} */
export const initReferrerList = () => [];

/** @return {TAllocation[]|undefined} */
export const initAllocationListOrUndefined = () => undefined;

/** @return {TLocation|undefined} */
export const initLocationOrUndefined = () => undefined;

/** @return {THardware[]|undefined} */
export const initHardwareListOrUndefined = () => undefined;

/** @return {THardware[]} */
export const initHardwareList = () => [];

/** @return {TPromoCode[]} */
export const initPromoCodeList = () => [];

/** @return {TBoot[]} */
export const initBootList = () => [];

/** @return {TSystemAlert[]} */
export const initalSystemAlertList = () => [];

/** @type {readonly TOrderState[]} */
export const initialOrderStateList = Object.freeze([]);

/**
 * @param {TOrderState[]} defaultValues
 * @return {TOrderState[]}
 * */
export const initialOrderStateListDefault = (defaultValues) => Array.from(new Set(defaultValues));

/** @return {any} */
export const initalAnyValuedAsUndefined = () => undefined;

/** @returns {SAS2.Reports.TWorkloadSummary} */
export const initWorkloadSummary = () => ({
  delivery: { orderCount: 0, skierCount: 0 },
  pickUp: { orderCount: 0, skierCount: 0 },
  total: { orderCount: 0, skierCount: 0 },
  items: [],
});
/** @returns {string[]} */
export const initStringArray = () => [];
/** @returns {Set<string>} */
export const initSetString = () => new Set();
/**
 * @template T
 * @param {T} defaultValue
 * @returns {string|T}
 * */
export const initStringOrDefault = (defaultValue) => defaultValue;
/**
 * @template T
 * @param {T} defaultValue
 * @returns {Date|T}
 * */
export const initDateOrDefault = (defaultValue) => defaultValue;

/** @return {import("firebase/auth").User|null} */
export const initFirebaseUserOrNull = () => null;

/** @return {TUserV2|null} */
export const initUserV2OrNull = () => null;

/** @return {TIUser|null} */
export const initUserOrNull = () => null;

/** @return {SAS2.Reports.TOverallSummary} */
export const initOverallSummary = () => ({
  orderDaysCount: 0,
  grossRevenue: 0,
  rentalSales: 0,
  skiPassSales: 0,
  skierDaysCount: 0,
  ordersCount: 0,
  skiersCount: 0,
  skierDayValue: 0,
  avgSkierPerOrder: 0,
  avgDaysPerOrder: 0,
  avgSkierDaysPerOrder: 0,
  avgYieldPerSkier: 0,
  avgSpendPerOrder: 0,
  lastMinuteOrdersCount: 0,
  percentageOfLastMinuteOrders: 0,
  skiPassDaysCount: 0,
  skiPassDaysConversionPercentage: 0,
  items: [],
});

/** @return {SAS2.Reports.TOverallSummaryToList[]} */
export const initOverallSummaryList = () => [];

/** @return {SAS2.Reports.TOverallSummaryItem[]} */
export const initOverallSummaryItems = () => [];

/** @return {SAS2.Reports.TStaffSummary} */
export const initStaffSummary = () => ({
  items: [],
  total: {
    bootRefitCount: 0,
    collectionCount: 0,
    deliveryCount: 0,
    refitPercentage: 0,
    deliverySkierCount: 0,
  },
});

/** @return {TDirectSupportCall[]} */
export const initDirectSupportCalls = () => [];
