import {
  Avatar,
  Box,
  Fade,
  Flex,
  HStack,
  Icon,
  Spacer,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { CheckIcon } from "@chakra-ui/icons";
import { BiSolidDollarCircle } from "react-icons/bi/index.esm.js";
import { FaBuildingCircleArrowRight, FaFileSignature } from "react-icons/fa6/index.esm.js";
import { FaDoorOpen, FaLock, FaSuitcase } from "react-icons/fa/index.esm.js";
import { HiExclamation, HiOutlineDocumentText, HiUsers } from "react-icons/hi/index.esm.js";
import { MdEventRepeat } from "react-icons/md/index.esm.js";
import { PiFirstAidFill, PiLockersBold } from "react-icons/pi/index.js";
import { RxDragHandleVertical } from "react-icons/rx/index.esm.js";
import { TiStarburst } from "react-icons/ti/index.esm.js";

import { getFieldAsDate } from "@snopro/common/firestore.js";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

import { useLoggedInUser } from "@/contexts/AuthContext.jsx";
import { useCountdownToDate } from "@/lib/datetime.js";
import { useShowError } from "@/lib/error.js";
import { updateOrderField } from "@/lib/order.api.js";

import { useOrderModalContext } from "../../contexts/OrderModalContext.jsx";
import SupportCallModal from "../modals/SupportCallModal.jsx";

/** @type {TOrderState[]} */
const __StatusNoOutstandingBalance = ["toSize", "toPack", "toDeliver"];

/**
 * @template {TOrder|TOrderSupportCall} TItem
 * @param {Object} props
 * @param {TItem} props.order
 * @param {"order"|"support"|"drive"} [props.type]
 * @param {boolean} [props.isSelected]
 * @param {(item: TItem) => void} [props.selectCard]
 * @param {boolean} [props.isLater]
 * @param {boolean} [props.isFaded]
 * @param {boolean} [props.isTransfer]
 * @returns {JSX.Element}
 */
export default function JobCard({
  order,
  isLater,
  type,
  isSelected,
  selectCard,
  isFaded,
  isTransfer,
}) {
  const { showError } = useShowError();
  const navigate = useNavigate();
  const { setModalId } = useOrderModalContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentUserDetails } = useLoggedInUser();
  const isCompact = type == "drive" || type == "support";
  /** @type {TOrder|null} */
  const parserOrder = "lookupId" in order ? order : null;
  const toast = useToast();
  /** @type {TOrderSupportCall|null} */
  const parserSupportCall = "type" in order && "lookupId" in order === false ? order : null;
  const endOFDateToday = dayjs.tz().endOf("day");
  let deadLine;

  if (
    parserOrder &&
    dayjs.tz(getFieldAsDate(parserOrder.bookingTime)).endOf("day").isSame(endOFDateToday)
  ) {
    deadLine = endOFDateToday.toDate().getTime();
  }

  const { timeLeftStr } = useCountdownToDate(deadLine);

  function openSupportCall() {
    onOpen();
  }

  const startFlow = async () => {
    try {
      if (parserOrder) {
        await updateOrderField(
          parserOrder,
          "activeUser",
          {
            id: currentUserDetails.id,
            name: currentUserDetails.firstName + " " + currentUserDetails.lastName,
            ...(currentUserDetails?.image && { image: currentUserDetails.image }),
          },
          `job-card`,
        );
      }
      switch (order.state) {
        case "toSize":
          navigate("/size/" + order.id);
          break;
        case "toPack":
          navigate("/pack/" + order.id);
          break;
        case "toDeliver":
          navigate("/drive/deliver/" + order.id);
          break;
        case "toCollect":
          navigate("/drive/collect/" + order.id);
          break;
        default:
          throw new Error(`Unknown order state [${order.state}]`);
      }
    } catch (error) {
      showError(error);
    }
  };

  if (!parserSupportCall && !parserOrder) {
    console.error("Unknown order type", order);
    return (
      <>
        Unknown
        {JSON.stringify(order).substring(0, 100)}
      </>
    );
  }

  function startCard() {
    if (order.state == "toSupport") {
      openSupportCall();
      return;
    }
    if (order.state == "toPack" && type == "drive") {
      toast({
        title: "Not packed! Can't start Delivery process.",
        status: "warning",
        duration: 3000,
        isClosable: false,
      });
      return;
    }
    startFlow();
  }

  return (
    <>
      <Fade in={true}>
        <HStack spacing="0" opacity={isFaded ? 0.5 : 1} position="relative">
          {parserOrder && parserOrder.activeUser && (
            <Avatar
              bg="brand.500"
              size="sm"
              position="absolute"
              mt="-90px"
              ml={["toDeliver", "toCollect"].includes(parserOrder.state) ? "12px" : "-16px"}
              name={parserOrder.activeUser.name}
              src={parserOrder.activeUser.image}
              zIndex="800"
            ></Avatar>
          )}
          {type == "drive" && (
            <Flex
              as="button"
              bg={isSelected ? "brand.500" : "gray.50"}
              borderColor={isSelected ? "brand.500" : ""}
              w="24px"
              m={0}
              h="100px"
              borderLeftRadius="16px"
              borderRightWidth="0"
              position="relative"
              left="6px"
              borderWidth={1}
              align="center"
              disabled={!selectCard}
              onClick={() => {
                if (selectCard) {
                  selectCard(order);
                }
              }}
            >
              <Icon as={RxDragHandleVertical} ml="4px"></Icon>
            </Flex>
          )}
          <Box
            as="button"
            bg={"gray.50"}
            borderWidth={1}
            borderRadius="lg"
            p={3}
            pl={3}
            h="100px"
            w={isCompact ? "220px" : "260px"}
            onClick={startCard}
            textAlign="left"
          >
            <Flex>
              {parserOrder?.state === "toSize" &&
                dayjs.tz(getFieldAsDate(parserOrder.bookingTime)).isToday() &&
                timeLeftStr &&
                deadLine && <Icon as={TiStarburst} color="brand.500" boxSize={5} />}
              {type != "support" &&
                "referrer" in order &&
                (order.previousOrder || order.referrer?.name == "Repeat Client") && (
                  <Icon boxSize={5} mt="1px" mr={1} color="brand.500" as={MdEventRepeat} />
                )}
              {type == "support" && "type" in order ? (
                <HStack>
                  <Text fontWeight="bold" isTruncated>
                    {order.type}
                  </Text>
                  {isTransfer && <Icon boxSize={5} as={FaBuildingCircleArrowRight}></Icon>}
                </HStack>
              ) : (
                <Text fontWeight="bold" isTruncated>
                  {order.firstName} {order.lastName}
                </Text>
              )}
              <Spacer />
              {type == "drive" || type == "support" ? (
                <HStack spacing="0">
                  {type == "drive" && (order.state == "toDeliver" || order.state == "toPack") && (
                    <>
                      <Icon as={FaDoorOpen} boxSize={6}></Icon>
                    </>
                  )}
                  {type == "drive" && order.state == "toCollect" && (
                    <>
                      <Icon as={PiLockersBold} boxSize={6}></Icon>
                    </>
                  )}
                  {(type == "drive" || type == "support") && order.state == "toSupport" && (
                    <>
                      <Icon as={PiFirstAidFill} boxSize={6}></Icon>
                    </>
                  )}
                </HStack>
              ) : (
                "deliverTime" in order && (
                  <HStack spacing={0}>
                    {dayjs.tz(getFieldAsDate(order.deliverTime)).diff(new Date(), "h") < 0 ? (
                      <Icon as={HiExclamation} boxSize={5} color="red.500" />
                    ) : (
                      dayjs.tz(getFieldAsDate(order.deliverTime)).diff(new Date(), "h") < 2 && (
                        <Icon as={HiExclamation} boxSize={5} color="orange.300" />
                      )
                    )}
                    {(order.state == "toSize" || order.state == "toPack") &&
                      order.deliveryLocked && <Icon as={FaLock} boxSize={3} mt="2px"></Icon>}
                    <Text fontWeight="bold" whiteSpace="nowrap">
                      {isLater
                        ? dayjs.tz(getFieldAsDate(order.deliverTime)).format("D MMM")
                        : dayjs.tz(getFieldAsDate(order.deliverTime)).format("h.mma")}
                    </Text>
                  </HStack>
                )
              )}
            </Flex>
            {order.state == "toDeliver" || order.state == "toSupport" || order.state == "toPack" ? (
              <Text isTruncated>{order.addressDelivery}&nbsp;</Text>
            ) : order.state == "toCollect" ? (
              <Text isTruncated>{parserOrder?.addressCollection ?? ""}&nbsp;</Text>
            ) : (
              <Text isTruncated>{parserOrder?.referrer?.name}&nbsp;</Text>
            )}
            {type == "support" && parserSupportCall ? (
              <HStack>
                {parserSupportCall.completedBy && <CheckIcon />}
                <Text fontWeight={parserSupportCall.completedBy ? "" : "bold"} isTruncated>
                  {parserSupportCall.completedBy
                    ? "(" + parserSupportCall.completedBy + ")"
                    : dayjs
                        .tz(getFieldAsDate(parserSupportCall.driveTime))
                        .format("ddd D MMM h.mma")}
                </Text>
                {parserSupportCall &&
                  dayjs.tz(getFieldAsDate(order.driveTime)).diff(new Date()) < 0 && (
                    <Icon as={HiExclamation} boxSize={5} color="red.500" />
                  )}
              </HStack>
            ) : (
              <HStack>
                {parserSupportCall?.state == "toSupport" ? (
                  <Text fontWeight="bold">{parserSupportCall.type}</Text>
                ) : (
                  parserOrder && (
                    <>
                      <Icon as={HiUsers} boxSize={4} mr={-1} />
                      <Text fontWeight="bold">{order.skierCount}</Text>
                      {((__StatusNoOutstandingBalance.includes(parserOrder.state) == false &&
                        parserOrder.balance !== 0) ||
                        parserOrder.paidStatus === "unpaid") && (
                        <Icon color="red.500" as={BiSolidDollarCircle} boxSize={4} />
                      )}
                      {parserOrder.state == "toDeliver" &&
                        Boolean(parserOrder.deliveryAcceptance?.signatureUrl) && (
                          <Icon color="brand.500" as={FaFileSignature} boxSize={4} />
                        )}
                      {parserOrder.state == "toPack" && type == "drive" && (
                        <Icon color="red.500" as={FaSuitcase} boxSize={3} />
                      )}
                    </>
                  )
                )}

                <Spacer />
                {parserOrder && parserOrder.state == "toDeliver" && parserOrder.deliveryLocked && (
                  <Icon as={FaLock} boxSize={3} mt="2px"></Icon>
                )}
                {type == "drive" && order.driveTime && (
                  <>
                    {parserOrder &&
                      (parserOrder.state == "toDeliver" || parserOrder.state == "toPack") &&
                      dayjs.tz(getFieldAsDate(parserOrder.deliverTime)).diff(new Date()) < 0 && (
                        <Icon as={HiExclamation} boxSize={5} color="red.500" />
                      )}
                    {parserOrder &&
                      parserOrder.state == "toCollect" &&
                      dayjs.tz(getFieldAsDate(parserOrder.collectTime)).diff(new Date()) < 0 && (
                        <Icon as={HiExclamation} boxSize={5} color="red.500" />
                      )}

                    {parserSupportCall &&
                      dayjs.tz(getFieldAsDate(order.driveTime)).diff(new Date()) < 0 && (
                        <Icon as={HiExclamation} boxSize={5} color="red.500" />
                      )}

                    <Text>{dayjs.tz(getFieldAsDate(order.driveTime)).format("h.mma")}</Text>
                  </>
                )}
              </HStack>
            )}
          </Box>
          {type != "support" && (
            <Box bg="gray.300" w={7} m={0} h={isCompact ? 16 : 20} borderRightRadius="xl">
              <Flex
                as="button"
                flexDirection="column"
                alignItems="center"
                h="100%"
                justify="space-evenly"
                onClick={(e) => {
                  setModalId(
                    parserSupportCall?.state == "toSupport" ? parserSupportCall.orderId : order.id,
                    Boolean(e.ctrlKey || e.shiftKey),
                  );
                }}
              >
                <Icon as={HiOutlineDocumentText} boxSize={7} color="white" />
              </Flex>
            </Box>
          )}
        </HStack>
      </Fade>
      {isOpen && parserSupportCall && (
        <SupportCallModal isOpen={isOpen} onClose={onClose} supportCall={parserSupportCall} />
      )}
    </>
  );
}
