import { createContext, useContext, useEffect, useState } from "react";

import { getConfigData } from "@/lib/configData.jsx";

/**
 * @returns {TConfigData}
 */
const initialConfigData = () => {
  // @ts-ignore
  return null;
};
export const ConfigContext = createContext(initialConfigData());

export function useConfig() {
  return useContext(ConfigContext);
}

export function ConfigProvider({ children }) {
  const [config, setConfig] = useState(initialConfigData());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  useEffect(() => {
    getConfigData()
      .then((data) => setConfig(data))
      .catch((error) => setError(error.message))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      {!loading && config && !error && (
        <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
      )}
    </>
  );
}
