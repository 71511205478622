import { useEffect, useMemo, useState } from "react";

import {
  Box,
  Button,
  Fade,
  Flex,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Text,
  VStack,
  Tooltip,
} from "@chakra-ui/react";

import { AiOutlineColumnWidth } from "react-icons/ai/index.esm.js";
import { FaFemale, FaLock, FaMale } from "react-icons/fa/index.esm.js";
import { HiExclamation } from "react-icons/hi/index.esm.js";

import { db } from "@/services/firebase.js";

import {
  collection,
  deleteField,
  doc,
  onSnapshot,
  query,
  where,
  writeBatch,
} from "firebase/firestore";

import { getFieldAsDate } from "@snopro/common/firestore.js";
import { LocationSchema } from "@snopro/common/models.js";
import dayjs from "dayjs";

import { useConfig } from "@/contexts/ConfigContext.jsx";
import calcTargetHardwareSize from "@/data/calcTargetHardwareSize.js";
import { compareAvailableHardwareV1, compareAvailableHardwareV2 } from "@/lib/availabilityLib.js";
import { useShowError } from "@/lib/error.js";
import {
  initAllocationListOrUndefined,
  initHardwareListOrUndefined,
} from "@/lib/initialize-states.js";
import { skierChanges } from "@/lib/skier.api.js";
import { useSASSystemConfig } from "@/lib/systemConfigLib.js";

import DateBox from "../common/DateBox.jsx";
import PackageName from "../common/PackageName.jsx";
import Availability from "./Availability.jsx";

/**
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {() => void} props.onClose
 * @param {TSkier} props.skier
 * @param {TOrder} props.order
 * @returns {JSX.Element}
 */
export default function AvailabilityModal({ isOpen, onClose, skier, order }) {
  const { SASSystemConfig } = useSASSystemConfig();
  const { showError } = useShowError();
  const [hardwareList, setHardwareList] = useState(initHardwareListOrUndefined());
  const [hardwareLocation, setHardwareLocation] = useState(order.location);
  const [allocations, setAllocations] = useState(initAllocationListOrUndefined());
  const [packageType, setPackageType] = useState(skier.packageType);
  const daysToShow = 11;
  const lookup = useConfig();

  const targetLength = useMemo(
    () => calcTargetHardwareSize(skier.height, skier.age, skier.ability, skier.packageType),
    [skier.height, skier.age, skier.ability, skier.packageType],
  );

  //allow for adjustments
  //convert to positive numbers for simplicity later in code
  //similar code in DayAdjuster and cloud function
  const dayAdjustStart =
    skier?.adjustments?.packageType_pos == "start" ? -(skier.adjustments.packageType_days ?? 0) : 0;
  const dayAdjustEnd =
    skier?.adjustments?.packageType_pos == "end" ? -(skier.adjustments.packageType_days ?? 0) : 0;

  const skierStart = useMemo(
    () => dayjs.tz(getFieldAsDate(order.deliverTime)).add(dayAdjustStart, "day"),
    [order.deliverTime, dayAdjustStart],
  );
  const skierEnd = dayjs.tz(getFieldAsDate(order.collectTime)).add(-dayAdjustEnd, "day");

  const skierDays = order.skiDays - dayAdjustStart - dayAdjustEnd;

  const dateRange = useMemo(() => {
    const value = [];
    //also show 2 days before
    for (let i = -2; i < daysToShow - 1; i++) {
      value.push(skierStart.add(i, "day"));
    }
    return value;
  }, [skierStart, daysToShow]);

  useEffect(() => {
    if (!isOpen) {
      setHardwareList([]);
      return;
    }
    const q =
      targetLength > 0
        ? query(
            collection(db, "hardware"),
            where("package", "==", packageType),
            where("location", "==", hardwareLocation),
            where("isActive", "==", true),
            where("lengthActual", ">=", targetLength - 30), //limit to realistic lengths
            where("lengthActual", "<=", targetLength + 30),
          )
        : query(
            collection(db, "hardware"),
            where("package", "==", packageType),
            where("location", "==", hardwareLocation),
            where("isActive", "==", true),
          );
    const unsub = onSnapshot(
      q,
      (querySnapshot) => {
        /** @type {THardware[]} */
        const list = querySnapshot.docs.map(
          /** @return {any} */ (doc) => ({
            ...doc.data(),
            id: doc.id,
          }),
        );
        list.sort(
          SASSystemConfig.availability?.smartSortingVersion === "v2"
            ? compareAvailableHardwareV2(skier, targetLength)
            : compareAvailableHardwareV1(skier, targetLength),
        );

        setHardwareList(list);
      },
      (error) => showError(error),
    );
    return unsub;
  }, [targetLength, isOpen, packageType, hardwareLocation, SASSystemConfig, showError, skier]);

  //get any matching allocations for the whole grid
  useEffect(() => {
    if (hardwareList) {
      //need date range in YYYYMMDD format to query
      const queryDates = [];
      dateRange.map((date) => {
        queryDates.push(dayjs.tz(date).format("YYYYMMDD"));
      });

      const q = query(
        collection(db, "allocations"),
        where("location", "==", hardwareLocation),
        where("dates", "array-contains-any", queryDates),
      );
      const unsub = onSnapshot(q, (querySnapshot) => {
        /** @type {any[]} */
        const list = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setAllocations(list);
      });
      return unsub;
    }
  }, [hardwareList, hardwareLocation, dateRange]);

  async function setHardware(hardware) {
    //save to firestore
    // TODO: move this to a lib function
    const batch = writeBatch(db);
    try {
      const skierDoc = doc(db, "orders", order.id, "skiers", skier.id);
      /** @type {Partial<TSkier>} */
      const skierUpdate = {
        selectedHardware: {
          id: hardware.id,
          location: hardware.location,
          make: hardware.make,
          model: hardware.model,
          code: hardware.code,
          length: hardware.length,
          package: hardware.package,
          type: hardware.type,
          locked: false,
        },
      };
      batch.update(skierDoc, skierUpdate);
      await skierChanges(order.id, skier.id, null, "update", skierUpdate, batch, "availability");

      //create dates array for availability matching
      const skiDates = [];

      //create date array for querying
      //if delivery after 1pm, then need to add a day to skiDays
      const totalDays = skierStart.hour() >= 13 ? skierDays + 1 : skierDays;

      for (let i = 0; i < totalDays; i++) {
        skiDates.push(skierStart.add(i, "day").format("YYYYMMDD"));
      }

      const allocationDoc = doc(db, "allocations", skier.id);
      /** @type {TAllocation} */
      const allocation = {
        id: allocationDoc.id,
        customerName: order.firstName + " " + order.lastName,
        skierName: skier.firstName + " " + skier.lastName,
        skierId: skier.id,
        hardwareId: hardware.id,
        hardwareName: hardware.make + " " + hardware.model,
        hardwareMake: hardware.make,
        hardwareModel: hardware.model,
        hardwareCode: hardware.code,
        hardwareType: packageType?.split(" ").pop(),
        packageType: packageType,
        orderId: order.id,
        ability: skier.ability,
        height: skier.height,
        start: skierStart.toDate(),
        end: skierEnd.toDate(),
        dates: skiDates,
        skiDays: skierDays,
        location: hardwareLocation,
        locked: false,
        adjusted: skierDays != order.skiDays,
      };
      batch.set(allocationDoc, allocation);
      await batch.commit();
      onClose();
    } catch (error) {
      showError(error);
    }
  }

  async function clearHardware() {
    try {
      const batch = writeBatch(db);
      const skierDoc = doc(db, "orders", order.id, "skiers", skier.id);
      const skierUpdate = { selectedHardware: deleteField() };
      batch.update(skierDoc, skierUpdate);
      batch.delete(doc(db, "allocations", skier.id));
      await skierChanges(order.id, skier.id, null, "update", skierUpdate, batch, "availability");
      await batch.commit();
      onClose();
    } catch (error) {
      showError(error);
    }
  }

  function convertRange(value, inMin, inMax, outMin, outMax) {
    return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
  }

  /**
   * @param {THardware} hardware
   */
  function renderRowAllocations(hardware) {
    const allocationsRow = [];
    let isFree = true;
    //get allocations for this harware row
    allocations?.forEach((allocation) => {
      if (allocation.hardwareId == hardware.id) {
        const dayOffset = Math.round(
          // need to round a float as default integer diff doesn't result in whole days
          dayjs
            .tz(getFieldAsDate(allocation.start))
            .startOf("day")
            .diff(dayjs.tz(skierStart.add(-2, "d").startOf("day").toDate()), "d", true),
        );

        const leftStart = 60 * dayOffset;
        //convert time 07-21 to 10-50 pixel offset
        const startOffset = convertRange(
          dayjs.tz(getFieldAsDate(allocation.start)).hour(),
          7,
          22,
          10,
          50,
        );
        const endOffset = convertRange(
          dayjs.tz(getFieldAsDate(allocation.end)).hour(),
          7,
          22,
          10,
          50,
        );
        const totalDays = dayjs(getFieldAsDate(allocation.end))
          .startOf("d")
          .diff(dayjs(getFieldAsDate(allocation.start)).startOf("d"), "d");
        allocationsRow.push(
          //allocation box
          <Box
            mt="1px"
            key={allocation.id}
            height="55px"
            left={leftStart + startOffset + "px"}
            width={totalDays * 60 + endOffset - startOffset + "px"}
            borderWidth={skier.id == allocation.skierId ? 0 : 1}
            borderColor={skier.id == allocation.skierId ? "brand.500" : "gray.300"}
            borderRadius={4}
            bg={skier.id == allocation.skierId ? "brand.500" : "gray.100"}
            pos="absolute"
            overflowWrap="normal"
            overflowX="hidden"
            overflowY="hidden"
            whiteSpace="nowrap"
            px="4px"
            fontSize="2xs"
          >
            <HStack overflowX="hidden">
              <Text fontSize="sm" fontWeight="bold">
                {allocation.skierName}
              </Text>
              <Text> {allocation.customerName}</Text>
            </HStack>
            <Flex mt="-2px" gap={1}>
              <Text>{lookup.height.cm[(allocation.height ?? 0) - 1]?.label}cm</Text>
              <Text fontWeight="bold">
                {(allocation.ability?.charAt(0)?.toUpperCase() ?? "") +
                  allocation.ability?.slice(1)}
              </Text>
              <Spacer />
            </Flex>

            <Flex mt="-2px" gap={1} align="center">
              {allocation.locked && <Icon as={FaLock} boxSize={3} mt="2px"></Icon>}
              <Text>{dayjs.tz(getFieldAsDate(allocation.start)).format("h.mma")}</Text>
              <Spacer />
              {allocation.adjusted && <AiOutlineColumnWidth />}
              <Spacer />
              {dayjs.tz(getFieldAsDate(allocation.end)).date() == skierStart.add(-2, "d").date() ? (
                <Tooltip label={dayjs.tz(getFieldAsDate(allocation.end)).format("h.mma")}>
                  <Text>{dayjs.tz(getFieldAsDate(allocation.end)).format("h.mma")}</Text>
                </Tooltip>
              ) : (
                <Text>{dayjs.tz(getFieldAsDate(allocation.end)).format("h.mma")}</Text>
              )}
            </Flex>
          </Box>,
        );

        if (
          dayjs.tz(getFieldAsDate(allocation.start)) <= skierEnd &&
          skierStart <= dayjs.tz(getFieldAsDate(allocation.end))
        ) {
          isFree = false;
        }
      }
    });

    if (allocations && isFree) {
      //TODO: add positioning for select box

      const startOffset = convertRange(dayjs.tz(getFieldAsDate(skierStart)).hour(), 7, 22, 10, 50);
      const endOffset = convertRange(dayjs.tz(getFieldAsDate(skierEnd)).hour(), 7, 22, 10, 50);
      const totalDays = dayjs(getFieldAsDate(skierEnd))
        .startOf("d")
        .diff(dayjs(getFieldAsDate(skierStart)).startOf("d"), "d"); //actually total - 1
      allocationsRow.push(
        //selection box
        <Box
          mt="1px"
          key={hardware.id}
          as="button"
          height="55px"
          left={120 + startOffset}
          width={totalDays * 60 + endOffset - startOffset + "px"}
          borderWidth={2}
          borderStyle="dashed"
          borderColor={hardware.status === "Unavailable" ? "grey.300" : "brand.500"}
          borderRadius={8}
          pos="relative"
          overflowWrap="normal"
          overflowX="hidden"
          overflowY="hidden"
          whiteSpace="nowrap"
          px="4px"
          textAlign="center"
          onClick={() => setHardware(hardware)}
          fontSize="2xs"
          color={hardware.status === "Unavailable" ? "grey" : "brand.500"}
        >
          <HStack spacing={1} height="full" align="flex-end">
            <Text>{dayjs.tz(getFieldAsDate(skierStart)).format("h.mma")}</Text>
            <Spacer />
            <Text>{dayjs.tz(getFieldAsDate(skierEnd)).format("h.mma")}</Text>
          </HStack>
        </Box>,
      );
    }

    return <>{allocationsRow}</>;
  }

  function renderBackgroundGrid(daysToShow) {
    const output = [];
    const width = 60;
    for (let i = 0; i < daysToShow + 1; i++) {
      output.push(
        <Box
          key={i}
          height="60px"
          position="absolute"
          left={`${i * width}px`}
          borderColor="gray.100"
          borderRightWidth={i + 1 == daysToShow + 1 ? "" : "2px"}
          borderBottomWidth="2px"
          width={width + "px"}
          borderStyle="dotted"
          bgColor={
            dateRange[0].add(i, "day").format("ddd") == "Sat" ||
            dateRange[0].add(i, "day").format("ddd") == "Sun"
              ? "gray.50"
              : ""
          }
        ></Box>,
      );
    }
    return output;
  }

  function renderPackageOptions() {
    const options = [];

    const skiPackages = [
      "Junior Ski",
      "Tweener Ski",
      "Progression Ski",
      "Performer Ski",
      "Premier Ski",
    ];
    const snowboardPackages = ["Junior Snowboard", "Performer Snowboard", "Premier Snowboard"];

    (skier.packageType?.split(" ").pop() == "Ski" ? skiPackages : snowboardPackages).forEach(
      (pkg) => {
        options.push(
          <option key={pkg} value={pkg}>
            {pkg}
          </option>,
        );
      },
    );

    return options;
  }

  function changePackageType(event) {
    setPackageType(event.target.value);
  }

  return (
    <Modal size="5xl" isOpen={isOpen} onClose={onClose} scrollBehavior="inside" trapFocus={false}>
      <ModalOverlay />
      <ModalContent minH="85%" maxH="85%">
        <ModalHeader fontWeight="normal" fontSize="sm">
          <HStack spacing={4}>
            <VStack spacing={0} align="left">
              <Text fontWeight="bold" fontSize="xl" isTruncated>
                {skier.packageType} for <Icon as={skier.gender === "Female" ? FaFemale : FaMale} />
                {skier.firstName} ({targetLength ? targetLength : "?"}cm)
              </Text>
              <HStack spacing={4}>
                <Text>{lookup.age.years[skier.age - 1]?.label}yrs</Text>
                <Text>{lookup.weight.kg[skier.weight - 1]?.label}kg</Text>
                <Text>{lookup.height.cm[skier.height - 1]?.label}cm</Text>
                <Text>{skier.ability}</Text>
              </HStack>
            </VStack>
            <Spacer />
            <Select
              defaultValue={hardwareLocation}
              onChange={(e) => setHardwareLocation(LocationSchema.parse(e.target.value))}
              w="fit-content"
              size="xs"
            >
              {["Queenstown", "Wanaka", "Hilton"].map((location) => (
                <option key={location} value={location}>
                  {location}
                </option>
              ))}
            </Select>
            <Spacer />
            {skier.selectedHardware && (
              <Button size="sm" onClick={() => clearHardware()}>
                Clear
              </Button>
            )}
            <Availability
              text="Full Availability"
              size="sm"
              isButton={true}
              startDate={getFieldAsDate(order.deliverTime)}
              defaultPackageType={packageType}
              defaultLocation={hardwareLocation}
            />
            <Box w="40px"></Box>
          </HStack>
          <HStack mt={6}>
            <Box w="260px">
              <Select
                variant="outline"
                w="260px"
                defaultValue={skier.packageType}
                onChange={changePackageType}
              >
                {renderPackageOptions()}
              </Select>
            </Box>

            <HStack spacing="10px">
              {dateRange.map((date, i) => (
                <DateBox
                  key={i}
                  date={date}
                  highlightWeekends={true}
                  dateRangeStart={skierStart.toDate()}
                  dateRangeEnd={skierEnd.toDate()}
                />
              ))}
            </HStack>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody position="static" pb={12} sx={{ "::-webkit-scrollbar": { display: "none" } }}>
          <Fade in={Boolean(allocations)}>
            {!targetLength && (
              <HStack mb={6}>
                <Icon as={HiExclamation} boxSize={5} color="red.500" />
                <Text>
                  {`Can't list by "Best Fit", showing all `}
                  {skier.packageType?.split(" ")?.pop()?.toLowerCase()}s
                </Text>
              </HStack>
            )}
            {hardwareList?.map((hardware) => (
              <HStack key={hardware.id} align="flex-start">
                <Box
                  w="260px"
                  h="60px"
                  color={hardware.status === "Unavailable" ? "gray" : undefined}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.open(
                      `/settings/hardware?location=${hardware.location}&hardwareId=${hardware.id}`,
                      "_blank",
                    );
                  }}
                >
                  <Text
                    fontSize={hardware.name.length > 28 ? "sm" : "md"}
                    fontWeight="bold"
                    isTruncated
                  >
                    {hardware.make} {hardware.model} {hardware.length}cm
                    {hardware.isFemale && <Icon position="relative" top="2px" as={FaFemale} />}
                  </Text>
                  <PackageName packageName={hardware.package} suffix={` (${hardware.code})`} />
                </Box>
                <Box
                  pos="relative"
                  top="0"
                  left="0"
                  overflow="hidden"
                  h="60px"
                  w={(daysToShow + 1) * 60 + "px"}
                >
                  {renderBackgroundGrid(daysToShow)}
                  {renderRowAllocations(hardware)}
                </Box>
              </HStack>
            ))}
          </Fade>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
