import { z } from "zod";

import { LocationSchema } from "./models.js";

export const LightspeedConfigSchema = z.object({
  register_id: z.string(),
  user_id: z.string(),
  skipass_product_id: z.string(),
  rentalSales_product_id: z.string(),
  apiUrl: z.string(),
  apiToken: z.string(),
  tax_id: z.string(),
});

export const SASSystemConfigSchema = z.object({
  availability: z
    .object({
      smartSortingVersion: z.string(),
    })
    .optional(),
  booking: z
    .object({
      /** @description separated multiple emails with "," */
      ccEmails: z.string().optional(),
      ccEmailsByLocation: z.record(LocationSchema, z.string()).optional(),
    })
    .optional(),
  mailChimp: z
    .object({
      apiKey: z.string().optional(),
      listId: z.string().optional(),
      server: z.string().optional(),
    })
    .optional(),
});
