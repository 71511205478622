import { plural } from "./string-utils.js";

/**
 * @param {TSkiPass|string} skiPass
 * @returns {Omit<TSkiPass,"price"|"status">|undefined}
 */
export function parseSkiPass(skiPass) {
  /** @type {TSkiPass["type"]|undefined} */
  let type;
  /** @type {TSkiPass["group"]|undefined} */
  let group;
  let days = 0;
  if (typeof skiPass === "string") {
    if (skiPass.startsWith("S")) {
      type = "Single";
    } else if (skiPass.startsWith("D")) {
      type = "Dual";
    }
    switch (skiPass.substring(1, 2)) {
      case "A":
        group = "Adult";
        break;
      case "C":
        group = "Child";
        break;
      case "F":
        group = "Free";
        break;
      case "T":
        group = "Student";
        break;
      case "S":
        group = "Senior";
        break;
    }
    days = parseInt(skiPass.substring(2));
  } else {
    type = skiPass.type;
    group = skiPass.group;
    days = skiPass.days;
  }

  if (!type || !group) {
    return;
  }

  return { type, group, days };
}
/**
 * @param {TSkiPass|string} skiPass
 * @param {{longDesc?:boolean}} [opts]
 * @returns {string}
 */
export function getSkiPassDescription(skiPass, opts) {
  let type = "";
  let group = "";
  let days = 0;
  if (typeof skiPass === "string") {
    if (skiPass.startsWith("S")) {
      type = "Single";
    } else if (skiPass.startsWith("D")) {
      type = "Dual";
    }
    switch (skiPass.substring(1, 2)) {
      case "A":
        group = "Adult";
        break;
      case "C":
        group = "Child";
        break;
      case "F":
        group = "Free";
        break;
      case "T":
        group = "Student";
        break;
      case "S":
        group = "Senior";
        break;
    }
    days = parseInt(skiPass.substring(2));
  } else {
    type = skiPass.type;
    group = skiPass.group;
    days = skiPass.days;
  }
  if (opts?.longDesc) {
    return [group, type === "Single" ? "Cardrona only" : "Flexible Dual Mountain", plural("day", days)]
      .filter(Boolean)
      .join("/");
  }
  return [group, type, days + "d"].filter(Boolean).join("/");
}
