import z from "zod";

import {
  OrderSchema,
  SkierSchema,
  CardDetailsSchema,
  OrderPaymentTransactionBaseSchema,
  UserSchema,
  ConfigDataPackageSchema,
  PackageTypeSchema,
  LocationSchema,
  ConfigDataProductSchema,
  SkiPassSchema,
  ConfigDataItemSchema,
  ReferrerSchema,
} from "./models.js";

export const UserAPICreatePayloadSchema = z.object({
  userName: z.string(),
  password: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  role: z.union([z.literal("admin"), z.literal("manager"), z.literal("tech")]),
});
export const UserAPIChangePasswordPayloadSchema = z.object({
  uid: z.string(),
  newPassword: z.string(),
});
export const UserAPIUpdatePayloadSchema = z.object({
  uid: z.string(),
  isActive: z.boolean().optional(),
  firstName: UserSchema.shape.firstName.optional(),
  lastName: UserSchema.shape.lastName.optional(),
  isTrainingModeEnabled: UserSchema.shape.isTrainingModeEnabled.optional(),
  role: UserSchema.shape.role.optional(),
  image: UserSchema.shape.image.optional(),
});

export const BookingAPIPaymentOptionsSchema = z.object({
  bookingPaymentHash: z.string(),
});
export const BookingAPIPaymentOptionsResponseSchema = z.object({
  depositAlreadyPaid: z.boolean(),
  lookupId: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  paymentOptions: z.array(
    z.object({
      description: z.string(),
      amount: z.number(),
    }),
  ),
});

export const BookingAPIConfigDataResponseSchema = z.object({
  locations: z.record(
    LocationSchema,
    z.object({
      packages: z.array(
        ConfigDataPackageSchema.extend({ packageType: PackageTypeSchema, isJunior: z.boolean() }),
      ),
      products: z.array(ConfigDataProductSchema),
      referrers: z.array(ReferrerSchema.pick({ id: true, name: true })),
    }),
  ),
  items: z.object({
    combo: ConfigDataItemSchema,
    helmet: ConfigDataItemSchema,
    jacket: ConfigDataItemSchema,
    pants: ConfigDataItemSchema,
    bootUpgrade: ConfigDataItemSchema,
    insurance: ConfigDataItemSchema,
    ownBoots: ConfigDataItemSchema,
  }),
  referrers: z.array(ReferrerSchema.pick({ id: true, name: true })),
  passes: z.array(SkiPassSchema.extend({ id: z.string() })),
});

export const BookingAPIMakePaymentSchema = z.object({
  bookingPaymentHash: z.string(),
  creditCard: CardDetailsSchema,
  amount: z.number().positive(),
});

export const BookingAPIGetReferrerInfoSchema = z.object({
  route: z.string(),
  location: LocationSchema,
});
export const BookingAPIGetReferrerInfoResponseSchema = ReferrerSchema.pick({
  includeSkiPassSales: true,
  name: true,
  route: true,
  location: true,
  id: true,
}).nullable();

export const BookingAPIApplyPromoCodeSchema = z.object({ code: z.string() });
export const BookingAPIApplyPromoCodeResponseSchema = z.object({
  id: z.string(),
  discountPercentage: z.number(),
});

const CreateOrderSkiPassPayloadSchema = z.object({
  id: z.string(),
  birthdate: z.string().regex(/^\d{2}\/\d{2}\/\d{4}$/),
  startDate: z.string().regex(/^\d{2}\/\d{2}\/\d{4}$/).optional(),
});
export const CreateOrderSkierPayloadSchema = SkierSchema.pick({
  firstName: true,
  lastName: true,
  gender: true,
  stance: true,
  ability: true,
  age: true,
  isJunior: true,
  height: true,
  weight: true,
  shoe: true,
  packageType: true,
  ownBoots: true,
  bootUpgrade: true,
  insurance: true,
  helmet: true,
  pants: true,
  jacket: true,
  extras: true,
  costs: true,
}).extend({
  skiPass: CreateOrderSkiPassPayloadSchema.optional().nullable(),
});

export const OrderAPICreatePayloadSchema = z.object({
  signupToMailList: z.boolean().optional(),
  order: OrderSchema.pick({
    firstName: true,
    lastName: true,
    email: true,
    phone: true,
    addressDelivery: true,
    addressCollection: true,
    location: true,
    skiDays: true,
    comments: true,
  })
    .required({
      firstName: true,
      lastName: true,
      addressDelivery: true,
      email: true,
      phone: true,
      location: true,
      skiDays: true,
    })
    .extend({
      deliverDate: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
      deliverTime: z.string().regex(/^\d{2}:\d{2}$/),
      collectDate: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
      collectTime: z.string().regex(/^\d{2}:\d{2}$/),
      referrerId: z.string(),
    }),
  skiers: z.array(CreateOrderSkierPayloadSchema),
  cardDetails: CardDetailsSchema.extend({ amount: z.number().min(10) }).optional(),
  promoCode: z.string().optional(),
  paymentOverride: z
    .object({
      enforceCentsValue: z.number().int().min(0).max(99).optional(),
    })
    .optional(),
});

export const OrderSaveCustomerSignaturePayloadSchema = z.object({
  orderId: z.string(),
  signatureImage: z
    .string()
    .startsWith("data:image/webp;base64,")
    .or(z.string().startsWith("data:image/png;base64,")),
});

export const OrderRefundNegativeBalancePayloadSchema = z.object({
  orderId: z.string(),
  source: OrderPaymentTransactionBaseSchema.shape.source,
});

export const OrderUnpaidDeliveryPayloadSchema = z.object({
  orderId: z.string(),
  reason: z.string(),
});

export const OrderIdPayloadSchema = z.object({
  orderId: z.string(),
  ignoreUnrefundedPayments: z.boolean().optional(),
});
export const OrderAPIDeletePayloadSchema = OrderIdPayloadSchema;
export const OrderAPIRecalculatePayloadSchema = OrderIdPayloadSchema;
export const OrderAPIDuplicatePayloadSchema = OrderIdPayloadSchema;

export const OrderProcessPaymentPayloadSchema = z
  .object({
    source: OrderPaymentTransactionBaseSchema.shape.source,
    orderId: z.string(),
    sikerId: z.string().optional(),
    type: z.literal("credit-card"),
    cardDetails: CardDetailsSchema,
    amount: z.number().positive(),
    comments: z.string().optional(),
  })
  .or(
    z.object({
      source: OrderPaymentTransactionBaseSchema.shape.source,
      orderId: z.string(),
      sikerId: z.string().optional(),
      type: z.union([z.literal("saved-credit-card"), z.literal("cash"), z.literal("on-account")]),
      amount: z.number().positive(),
      comments: z.string().optional(),
    }),
  )
  .or(
    z.object({
      source: OrderPaymentTransactionBaseSchema.shape.source,
      orderId: z.string(),
      type: z.literal("refund"),
      amount: z.number().positive(),
      id: z.string(),
      comments: z.string().optional(),
    }),
  )
  .or(
    z.object({
      source: OrderPaymentTransactionBaseSchema.shape.source,
      orderId: z.string(),
      type: z.literal("refund"),
      amount: z.number().positive(),
      method: z.literal("on-account"),
      comments: z.string().optional(),
    }),
  );

export const LightspeedCreateSalesPayloadSchema = OrderIdPayloadSchema;
