import React from "react";

import { Box, Container, Grid, GridItem, HStack, SlideFade, Text, VStack } from "@chakra-ui/react";

import { getFieldAsDate } from "@snopro/common/firestore.js";
import dayjs from "dayjs";

export default function StepPackBagTag({ order }) {
  function getSize(height) {
    if (height <= 3) return "JR";
    if (height <= 9) return "S";
    if (height <= 14) return "M";
    if (height <= 16) return "L";
    return "XL";
  }

  return (
    <SlideFade in={true} offsetX={12} offsetY={0}>
      <Box width="1000px">
        <Container>
          <Box borderWidth={4} borderRadius={20} p={10} width="600px" textAlign="center" mb={20}>
            <VStack>
              <Text fontSize="4xl" fontWeight="bold" fontFamily="Kalam">
                {order.firstName + " " + order.lastName} ({order.skierCount})
              </Text>
              <Text fontSize="2xl" fontFamily="Kalam">
                {order.addressDelivery}
              </Text>
              <Text fontSize="2xl" fontFamily="Kalam">
                {dayjs.tz(getFieldAsDate(order.deliverTime)).format("h:mma")}
              </Text>
            </VStack>
          </Box>
        </Container>
        <Grid templateColumns="0.5fr 1.8fr 0.8fr 1fr 1fr" gap={4} fontSize="sm">
          {order?.skiers.map((skier, i) => (
            <React.Fragment key={skier.id}>
              <GridItem>
                <Text fontWeight="bold">{skier.firstName}</Text>
              </GridItem>
              <GridItem>
                <HStack divider={<Text px="1">·</Text>}>
                  <Text>{skier.packageType}</Text>
                  {skier.packageType?.split(" ").pop() == "Ski" && <Text>Poles 115mm</Text>}
                </HStack>
              </GridItem>
              <GridItem>
                <Text isTruncated>
                  {skier.selectedBoot && !skier.ownBoots && (
                    <>
                      {skier.selectedBoot?.brand +
                        " " +
                        skier.selectedBoot?.model +
                        " " +
                        skier.shoe}
                    </>
                  )}
                </Text>
              </GridItem>
              <GridItem>
                <HStack divider={<Text px="1">·</Text>}>
                  {skier.jacket && <Text>{getSize(skier.height)} Jacket</Text>}
                  {skier.pants && <Text>{getSize(skier.height)} Pants</Text>}
                  {skier.helmet && <Text>Helmet</Text>}
                </HStack>
              </GridItem>
              <GridItem>
                <HStack divider={<Text px="1">·</Text>}>
                  {skier.extras?.map((product, i) => (
                    <Text key={i}>{product.name}</Text>
                  ))}
                </HStack>
              </GridItem>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </SlideFade>
  );
}
