import { useState } from "react";

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  VStack,
  Icon,
  Divider,
} from "@chakra-ui/react";

import { FaTrash } from "react-icons/fa/index.esm.js";

import { useShowError } from "@/lib/error.js";
import { updateOrderField } from "@/lib/order.api.js";

/**
 *
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {() => void} props.onClose
 * @param {TOrder} props.order
 * @returns {JSX.Element}
 */
export default function RemovePromoFromOrderModal({ isOpen, onClose, order }) {
  const { showError } = useShowError();
  const [isDeleting, setIsDeleting] = useState(false);
  if (!order.promoCode) {
    return <></>;
  }
  return (
    <Modal isOpen={Boolean(isOpen)} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Are you sure you want to remove the promo code?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={0}>
            <Divider />
            <Text fontWeight="bold" p={4}>
              {[order.promoCode?.code, order.promoCode?.discount * 100 + "%"]
                .filter(Boolean)
                .join(" - ")}
            </Text>
            <Divider />
            <Divider />
          </VStack>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button isDisabled={isDeleting} size="sm" onClick={onClose}>
            CANCEL
          </Button>
          <Spacer />
          <Button
            size="sm"
            isLoading={isDeleting}
            onClick={async () => {
              setIsDeleting(true);
              try {
                await updateOrderField(order, "promoCode", null, "order-page");
                onClose();
              } catch (error) {
                showError(error);
              } finally {
                setIsDeleting(false);
              }
            }}
            leftIcon={<Icon as={FaTrash} />}
          >
            REMOVE
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
