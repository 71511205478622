import { Fragment, useMemo } from "react";

import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Text,
  Tabs,
  Box,
  Divider,
  Badge,
} from "@chakra-ui/react";

import { db } from "@/services/firebase.js";

import { doc, updateDoc, writeBatch } from "firebase/firestore";

import { getFieldAsDate } from "@snopro/common/firestore.js";
import { PackageTypeSchema, TypeOfCostsSchema } from "@snopro/common/models.js";
import dayjs from "dayjs";

import { getLoggedInUser } from "@/contexts/AuthContext.jsx";
import { useConfigData } from "@/lib/configData.jsx";
import { useShowError } from "@/lib/error.js";
import { compareProduct } from "@/lib/productsLib.js";
import {
  skierAddMiscItem,
  skierAddPackageType,
  skierAddProduct,
  skierChanges,
  skierAddSundriesItem,
} from "@/lib/skier.api.js";
import { registerSystemAlertDeliveredOrderSkiPassAdded } from "@/lib/systemAlertLib.js";

import AddSkiPassList from "../flows/common/AddSkiPassList.jsx";

/**
 * @param {Object} props
 * @param {TSkier} props.skier
 * @param {TOrder} props.order
 * @returns {JSX.Element}
 */
export default function AddItemModal({ skier, order }) {
  const { showError } = useShowError();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const configData = useConfigData();
  const ageGroup = skier.isJunior ? "junior" : "adult";

  const sundriesList = useMemo(() => {
    return Object.values(configData.sundries ?? {}).sort((a, b) => a.name.localeCompare(b.name));
  }, [configData.sundries]);

  // TODO: move to skier.api.js
  /** @param {TTypeOfCosts} item */
  async function addRentalItem(item) {
    const skierDoc = doc(db, "orders", order.id, "skiers", skier.id);
    let updatePayload;
    try {
      if (
        item == "combo" ||
        (item == "jacket" && skier.pants) ||
        (item == "pants" && skier.jacket)
      ) {
        updatePayload = {
          jacket: true,
          pants: true,
          "costs.combo": Number(configData.items.combo[skier.isJunior ? "junior" : "adult"]),
          "costs.pants": 0,
          "costs.jacket": 0,
        };
      } else {
        updatePayload = {
          [item]: true,
          [`costs.${item}`]: Number(configData.items[item][skier.isJunior ? "junior" : "adult"]),
        };
      }
      await updateDoc(skierDoc, updatePayload);
      await skierChanges(order.id, skier.id, null, "update", updatePayload);

      onClose();
    } catch (/** @type {any} */ error) {
      showError(error);
    }
  }

  async function addMiscItem(item) {
    await skierAddMiscItem(order.id, skier.id, item)
      .then(() => onClose())
      .catch(showError);
  }

  async function addSundriesItem(item) {
    await skierAddSundriesItem(order.id, skier.id, item)
      .then(() => onClose())
      .catch(showError);
  }

  /** @param {string} productKey */
  async function addProduct(productKey) {
    await skierAddProduct(order.id, skier.id, order.location, productKey)
      .then(() => onClose())
      .catch(showError);
  }

  /** @param {TPackageType} packageType */
  async function addPackageType(packageType) {
    await skierAddPackageType(order.id, skier.id, order.location, packageType)
      .then(() => onClose())
      .catch(showError);
  }

  // TODO: move to skier.api.js
  async function addSkiPass(pass, birthDay, firstDay) {
    try {
      const batch = writeBatch(db);
      const skierDoc = doc(db, "orders", order.id, "skiers", skier.id);
      const updatePayload = {
        "skiPass.birthdate": birthDay,
        "skiPass.startDate": firstDay,
        "skiPass.id": pass.id,
        "skiPass.activated": false,
        "skiPass.passInfo": "",
        "costs.skiPass": Number(pass.price),
      };
      await registerSystemAlertDeliveredOrderSkiPassAdded(order, getLoggedInUser(), batch);
      batch.update(skierDoc, updatePayload);
      await skierChanges(order.id, skier.id, null, "update", updatePayload, batch);
      await batch.commit();
      onClose();
    } catch (error) {
      showError(error);
    }
  }
  const showPackages = useMemo(() => {
    return (
      Boolean(skier.packageType) == false &&
      Object.values(skier.sundries ?? {}).findIndex((s) => s.packageType) === -1
    );
  }, [skier]);

  return (
    <>
      <Button size="xs" onClick={onOpen}>
        add item
      </Button>
      {isOpen && (
        <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent
            minH="60%"
            overflow="auto"
            sx={{ "::-webkit-scrollbar": { display: "none" } }}
            mt={24}
          >
            <ModalHeader>Add Item</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box w="full" h="full">
                <Tabs
                  variant="line"
                  colorScheme="brand"
                  defaultIndex={0}
                  h="100%"
                  align="center"
                  isLazy
                >
                  <TabList>
                    {showPackages && (
                      <Tab>
                        <Text ml={1}>Packages</Text>
                      </Tab>
                    )}
                    <Tab>
                      <Text ml={1}>Rental Items</Text>
                    </Tab>
                    <Tab>
                      <Text ml={1}>Sale Items</Text>
                    </Tab>
                    <Tab>
                      <Text ml={1}>Ski Passes</Text>
                    </Tab>
                    <Tab>
                      <Text ml={1}>Sundries</Text>
                    </Tab>
                  </TabList>
                  <TabPanels h="100%">
                    {showPackages && (
                      <TabPanel p={0} h="full">
                        {Object.entries(configData.packages[order.location] ?? {})
                          .sort(([, lV], [, rV]) => lV.sortOrder - rV.sortOrder)
                          .map(([key, packageType]) => {
                            const packageKey = PackageTypeSchema.parse(key);
                            return (
                              <Fragment key={packageType.code}>
                                <Divider />
                                <HStack
                                  spacing={0}
                                  as="button"
                                  onClick={addPackageType.bind(null, packageKey)}
                                >
                                  <Text fontWeight="bold" p={4}>
                                    {packageKey}
                                  </Text>
                                  <Text color="gray.300">${packageType.price}</Text>
                                </HStack>
                              </Fragment>
                            );
                          })}
                      </TabPanel>
                    )}
                    <TabPanel p={0} h="full">
                      {Object.entries(configData.items).map(([key, item], index) => {
                        const parsedItemKey = TypeOfCostsSchema.safeParse(key);
                        if (!parsedItemKey.success) {
                          return null;
                        }
                        const itemKey = parsedItemKey.data;
                        if (!skier[itemKey])
                          return (
                            <Fragment key={index}>
                              <Divider />
                              <HStack
                                spacing={0}
                                as="button"
                                onClick={() => addRentalItem(itemKey)}
                              >
                                <Text fontWeight="bold" p={4}>
                                  {item.name}
                                </Text>
                                <Text color="gray.300">${item[ageGroup.toLowerCase()]}</Text>
                              </HStack>
                            </Fragment>
                          );
                      })}
                      {Object.keys(configData.misc).map((itemKey, index) => {
                        const item = configData.misc[itemKey];
                        if (!skier[itemKey])
                          return (
                            <Fragment key={index}>
                              <Divider />
                              <HStack spacing={0} as="button" onClick={() => addMiscItem(itemKey)}>
                                <Text fontWeight="bold" p={4}>
                                  {item.name}
                                </Text>
                                <Text color="gray.300">${item.price}</Text>
                              </HStack>
                            </Fragment>
                          );
                      })}
                    </TabPanel>
                    <TabPanel p={0} h="full">
                      {Object.entries(configData.products[order.location] ?? {})
                        .sort(compareProduct)
                        .map(([productKey, product], index) => {
                          if (
                            (ageGroup === "junior" || product.size == ageGroup) &&
                            !skier.extras?.some((p) => p.code === product.code)
                          )
                            return (
                              <Fragment key={index}>
                                <Divider />
                                <HStack
                                  spacing={0}
                                  as="button"
                                  onClick={() => addProduct(productKey)}
                                >
                                  <Text fontWeight="bold" p={4}>
                                    {product.name}
                                  </Text>
                                  <Text color="gray.300">${product.price}</Text>
                                </HStack>
                              </Fragment>
                            );
                        })}
                    </TabPanel>
                    <TabPanel p={0} h="full">
                      <AddSkiPassList
                        addSkiPass={addSkiPass}
                        firstSkiDay={dayjs
                          .tz(getFieldAsDate(order.deliverTime))
                          .format("DD/MM/YYYY")}
                      />
                    </TabPanel>

                    <TabPanel p={0} h="full">
                      {sundriesList
                        .filter((s) => {
                          if (skier.packageType && s.packageType) {
                            return false;
                          }
                          if (
                            Object.values(skier.sundries ?? {}).find((ss) => ss.sundriesId === s.id)
                          ) {
                            return false;
                          }
                          return true;
                        })
                        .map((item) => {
                          return (
                            <Fragment key={item.id}>
                              <Divider />
                              <HStack spacing={4} as="button" onClick={() => addSundriesItem(item)}>
                                <Text fontWeight="bold" p={4}>
                                  {item.name}
                                </Text>
                                <Text color="gray.300">${item.amount}</Text>
                                <Badge>{item.type}</Badge>
                              </HStack>
                            </Fragment>
                          );
                        })}
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
