import React, { useEffect, useMemo, useState } from "react";

import {
  Avatar,
  Badge,
  Box,
  Button,
  Fade,
  Grid,
  GridItem,
  HStack,
  Heading,
  IconButton,
  SlideFade,
  Spacer,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { PiFirstAidFill } from "react-icons/pi/index.js";

import { db } from "@/services/firebase.js";

import {
  collection,
  collectionGroup,
  deleteField,
  doc,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import { getFieldAsDate } from "@snopro/common/firestore.js";
import { OrderSchema } from "@snopro/common/models.js";
import { plural } from "@snopro/common/string-utils.js";
import { useNavigate, useParams } from "react-router-dom";

import { useShowError } from "@/lib/error.js";
import { initialSupportCalls, initOrderList } from "@/lib/initialize-states.js";
import { updateOrder } from "@/lib/order.api.js";
import { updateDirectSupportCall, useDirectSupportCalls } from "@/lib/supportCallLib.jsx";

import { getUserAvatarByUID, useLoggedInUser } from "../../contexts/AuthContext.jsx";
import { datepickerLocale } from "../../data/datepickerLocale.js";
import dayjs from "../../lib/datetime.js";
import DropZone from "../common/DropZone.jsx";
import JobCard from "../common/JobCard.jsx";
import JobCardDirectSupport from "../common/JobCardDirectSupport.jsx";
import Legend from "../common/Legend.jsx";
import ScheduleDivider from "../common/ScheduleDivider.jsx";
import DirectSupportCallModal from "../modals/DirectSupportCallModal.jsx";
import TechSelectModal from "../modals/TechSelectModal.jsx";
import VanStockModal from "../modals/VanStockModal.jsx";

/**
 * @typedef {{id:string; name:string; label:string}} TTech
 */

/** @returns {undefined|Record<`tech${string}`,TTech>} */
const initialTechInfo = () => undefined;
/** @returns {number|undefined} */
const initialNumberOrUndefined = () => undefined;
/** @returns {(TOrder|TOrderSupportCall)[]} */
const initialAllJobs = () => [];

/** @param {TTech|undefined} tech */
function getAvatarUrl(tech) {
  if (!tech || !tech.id) {
    return;
  }

  return getUserAvatarByUID(tech.id);
}

/** @param {dayjs.Dayjs} timeSlotDate */
const getTimeSlotId = (timeSlotDate) => `time-slot-${timeSlotDate.format("HHmm")}`;

export default function Drive() {
  const newDirectSupportCallDisclosure = useDisclosure();

  const { showError } = useShowError();
  const { displayDate } = useParams();
  const navigate = useNavigate();
  const selectedDay = useMemo(
    () => (displayDate ? dayjs.tz(displayDate).startOf("day") : dayjs.tz().startOf("day")),
    [displayDate],
  );
  const [orders, setOrders] = useState(initOrderList());
  const [supportCalls, setSupportCalls] = useState(initialSupportCalls());
  const [allJobs, setAllJobs] = useState(initialAllJobs());
  const [selectedItemId, setSelectedItemId] = useState("");
  const { supportCalls: directSupportCallList } = useDirectSupportCalls({
    date: selectedDay,
  });
  const selectedItem = useMemo(() => {
    return (
      orders.find((order) => order.id === selectedItemId) ||
      supportCalls.find((supportCall) => supportCall.id === selectedItemId) ||
      directSupportCallList.find((supportCall) => supportCall.id === selectedItemId) ||
      null
    );
  }, [selectedItemId, orders, directSupportCallList, supportCalls]);
  const selectedOrder = useMemo(
    () => orders.find((order) => order.id === selectedItemId),
    [orders, selectedItemId],
  );

  const selectedCardType = useMemo(() => {
    if (selectedItem) {
      if (directSupportCallList.find((supportCall) => supportCall.id === selectedItemId)) {
        return "directSupportCall";
      }
      if ("state" in selectedItem) {
        return "order";
      } else {
        return "supportCall";
      }
    }
    return "";
  }, [directSupportCallList, selectedItem, selectedItemId]);

  const { currentUserDetails } = useLoggedInUser();
  const techSuffix = "_" + currentUserDetails.defaultLocation;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTech, setSelectedTech] = useState(initialNumberOrUndefined());
  const [techInfo, setTechInfo] = useState(initialTechInfo());
  const toast = useToast();

  useEffect(() => {
    const nextDay = dayjs.tz().add(1, "day").startOf("day").toDate().getTime() - +new Date();
    const nextUrl = selectedDay.isToday() ? "/drive" : `/drive/${selectedDay.format("YYYY-MM-DD")}`;
    const timeout = setTimeout(() => navigate(nextUrl, { replace: true }), nextDay);
    return () => clearTimeout(timeout);
  }, [navigate, selectedDay]);

  const timeSlots = useMemo(() => {
    /** @type {dayjs.Dayjs[]} */
    const list = [];

    for (let i = 7; i <= 21; i++) {
      list.push(dayjs.tz(selectedDay).set("hour", i).startOf("hour"));
      list.push(dayjs.tz(selectedDay).set("hour", i).startOf("hour").set("minute", 30));
    }
    return list;
  }, [selectedDay]);

  useEffect(() => {
    if (selectedDay.isToday()) {
      const slotToFocusOn = dayjs.tz().subtract(45, "minutes").startOf("hour");
      document.getElementById(getTimeSlotId(slotToFocusOn))?.scrollIntoView({ behavior: "smooth" });
    }
    setSelectedItemId("");
  }, [selectedDay]);

  useEffect(() => {
    const selectedDayStart = selectedDay.startOf("day").toDate();
    const selectedDayEnd = selectedDay.endOf("day").toDate();
    const q = query(
      collection(db, "orders"),
      where("team", "==", currentUserDetails?.defaultLocation),
      where("driveTime", "<=", selectedDayEnd),
      where("driveTime", ">=", selectedDayStart),
      where("state", "in", ["toPack", "toDeliver", "toCollect"]),
      orderBy("driveTime", "asc"),
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const orders = querySnapshot.docs.map((doc) =>
        OrderSchema.parse({ ...doc.data(), id: doc.id }),
      );
      setOrders(orders);
    });
    return unsubscribe;
  }, [currentUserDetails?.defaultLocation, selectedDay]);

  useEffect(() => {
    const selectedDayStart = selectedDay.startOf("day").toDate();
    const selectedDayEnd = selectedDay.endOf("day").toDate();

    const q = query(
      collectionGroup(db, "supportCalls"),
      where("location", "==", currentUserDetails?.defaultLocation),
      where("driveTime", "<=", selectedDayEnd),
      where("driveTime", ">=", selectedDayStart),
      where("isActive", "==", true),
      orderBy("driveTime", "asc"),
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      /** @type {any[]} */
      const supportCalls = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setSupportCalls(supportCalls);
    });
    return unsubscribe;
  }, [currentUserDetails?.defaultLocation, selectedDay]);

  useEffect(() => {
    const list = initialAllJobs().concat(orders).concat(supportCalls);
    list.sort(function (a, b) {
      return getFieldAsDate(a.driveTime) - getFieldAsDate(b.driveTime);
    });
    setAllJobs(list);
  }, [orders, supportCalls]);

  useEffect(() => {
    const docRef = doc(db, "techs", dayjs(selectedDay).format("YYYYMMDD"));
    const unsub = onSnapshot(docRef, (docSnap) => {
      setTechInfo(docSnap.data());
    });
    return unsub;
  }, [selectedDay]);

  /**
   * @param {{ id:string; driveTime?: Date}} item
   * @param {boolean} [isUnassigned]
   */
  function selectCard(item, isUnassigned) {
    setSelectedItemId(item.id == selectedItem?.id ? "" : item.id);
    if (isUnassigned && !selectedItem) {
      document
        .getElementById(getTimeSlotId(dayjs.tz(getFieldAsDate(item.driveTime))))
        ?.scrollIntoView({ behavior: "smooth" });
    }
  }

  function checkChangedSkiDays(originalHour, newHour, thresholdHour) {
    if (
      (originalHour < thresholdHour && newHour >= thresholdHour) ||
      (originalHour >= thresholdHour && newHour < thresholdHour)
    ) {
      toast({
        title:
          "Number of ski days have now changed for that booking! Be aware that cost to customer has changed.",
        status: "warning",
        duration: null,
        isClosable: true,
      });
    }
  }

  async function updateSchedule(timeSlot, driver) {
    if (!selectedItem) {
      return;
    }
    try {
      if (timeSlot && "state" in selectedItem) {
        switch (selectedItem.state) {
          case "toDeliver":
          case "toPack":
            {
              if (!selectedOrder) {
                return;
              }
              const newDeliverTime = dayjs
                .tz(getFieldAsDate(selectedOrder.deliverTime))
                .set("hour", timeSlot.hour())
                .set("minute", timeSlot.minute())
                .toDate();
              await updateOrder(
                selectedOrder,
                {
                  deliverTime: newDeliverTime,
                  driveTime: newDeliverTime,
                  assignedTo: driver,
                },
                "driver-page",
              );
              checkChangedSkiDays(
                dayjs.tz(getFieldAsDate(selectedOrder.deliverTime)).hour(),
                timeSlot.hour(),
                13,
              );
            }
            break;
          case "toCollect":
            {
              if (!selectedOrder) {
                return;
              }
              const newCollectTime = dayjs
                .tz(getFieldAsDate(selectedOrder.collectTime))
                .set("hour", timeSlot.hour())
                .set("minute", timeSlot.minute())
                .toDate();
              await updateOrder(
                selectedOrder,
                {
                  collectTime: newCollectTime,
                  driveTime: newCollectTime,
                  assignedTo: driver,
                },
                "driver-page",
              );
              checkChangedSkiDays(
                dayjs.tz(getFieldAsDate(selectedOrder.collectTime)).hour(),
                timeSlot.hour(),
                11,
              );
            }
            break;
          case "toSupport": {
            const newSupportTime = dayjs
              .tz(getFieldAsDate(selectedItem.driveTime))
              .set("hour", timeSlot.hour())
              .set("minute", timeSlot.minute())
              .toDate();
            const supportDoc = doc(
              db,
              "orders",
              selectedItem.orderId,
              "supportCalls",
              selectedItem.id,
            );

            await updateDoc(supportDoc, { driveTime: newSupportTime, assignedTo: driver });
            break;
          }
          default:
            break;
        }
      } else {
        if ("state" in selectedItem && selectedItem.state == "toSupport") {
          const supportDoc = doc(
            db,
            "orders",
            selectedItem.orderId,
            "supportCalls",
            selectedItem.id,
          );
          await updateDoc(supportDoc, { assignedTo: deleteField() });
        } else {
          if (selectedOrder) {
            await updateOrder(selectedOrder, { assignedTo: deleteField() }, "driver-page");
          }
        }
      }

      setSelectedItemId("");
    } catch (error) {
      showError(error);
    }
  }
  /**
   * @param {dayjs.Dayjs} timeSlot
   * @param {string} driver
   * @returns {JSX.Element}
   */
  function displayJobsForTimeslot(timeSlot, driver) {
    const jobsToDisplay = [];
    const timeSlotString = timeSlot.format("HHmm");

    allJobs.forEach((job) => {
      if (
        job.assignedTo == driver &&
        dayjs.tz(getFieldAsDate(job.driveTime)).format("HHmm") == timeSlotString
      ) {
        jobsToDisplay.push(
          <JobCard
            key={job.id}
            order={job}
            type="drive"
            isSelected={selectedItem?.id == job.id}
            selectCard={() => selectCard(job)}
          />,
        );
      }
    });

    directSupportCallList.forEach((supportCall) => {
      const jobTimeSlotString = dayjs.tz(getFieldAsDate(supportCall.driveTime)).format("HHmm");
      if (supportCall.assignedTo == driver && timeSlotString == jobTimeSlotString) {
        jobsToDisplay.push(
          <JobCardDirectSupport
            key={supportCall.id}
            isSelected={selectedItem?.id == supportCall.id}
            onSelectCard={(item) => selectCard(item)}
            supportCall={supportCall}
          />,
        );
      }
    });

    //TODO: don't show dropzone for locked *deliveries, see above

    if (selectedItem) {
      if ("state" in selectedItem === false) {
        // direct support call
        const jobTimeString = dayjs.tz(getFieldAsDate(selectedItem.driveTime)).format("HHmm");
        if (selectedItem.deliveryLocked !== true || jobTimeString === timeSlotString) {
          jobsToDisplay.push(
            <DropZone
              key={timeSlotString + driver + "dropzone"}
              handleUpdate={() => {
                updateDirectSupportCall(selectedItem, {
                  assignedTo: driver,
                  driveTime: timeSlot.toDate(),
                })
                  .then(() => setSelectedItemId(""))
                  .catch(showError);
              }}
              isFaded={jobTimeString != timeSlotString}
              position="absolute"
            />,
          );
        }
      } else {
        //show dropzone
        if (
          !(
            selectedItem.assignedTo == driver &&
            dayjs.tz(getFieldAsDate(selectedItem.driveTime)).format("HHmm") == timeSlotString
          ) &&
          !(
            (selectedItem.state == "toDeliver" || selectedItem.state == "toPack") &&
            "deliveryLocked" in selectedItem &&
            selectedItem.deliveryLocked &&
            dayjs.tz(getFieldAsDate(selectedItem.deliverTime)).format("HHmm") != timeSlotString
          )
        ) {
          jobsToDisplay.push(
            <DropZone
              key={timeSlotString + driver + "dropzone"}
              handleUpdate={() => updateSchedule(timeSlot, driver)}
              isFaded={
                dayjs.tz(getFieldAsDate(selectedItem.driveTime)).format("HHmm") != timeSlotString
              }
              position="absolute"
            />,
          );
        }
      }
    }

    return (
      <VStack key={dayjs.tz(timeSlot).format("HHmm") + driver} position="relative">
        {jobsToDisplay}
      </VStack>
    );
  }

  function selectDate(date) {
    setSelectedDay(dayjs.tz(new Date(date.year, date.month - 1, date.day)));
  }

  function setSelectedDay(date) {
    navigate("/drive/" + dayjs.tz(date).format("YYYY-MM-DD"));
  }

  const formatInputValue = () => {
    if (!selectedDay) return "";
    return selectedDay.format("ddd d MMM");
  };

  const unassignSelectedJob = async () => {
    if (!selectedItem) {
      showError(new Error("No job selected"));
      return;
    }
    try {
      if ("state" in selectedItem === false && selectedCardType === "directSupportCall") {
        await updateDirectSupportCall(selectedItem, { assignedTo: deleteField() });
        setSelectedItemId("");
        return;
      }
      await updateSchedule(null, null);
    } catch (error) {
      showError(error);
    }
  };

  const renderCustomInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      placeholder=""
      value={selectedDay ? selectedDay.format("ddd D MMM") : ""}
      style={{
        textAlign: "center",
        fontFamily: "var(--chakra-fonts-heading)",
        padding: "4px",
        fontSize: "1.1rem",
        border: "0px",
        borderRadius: "100px",
        outline: "none",
        cursor: "pointer",
        width: "150px",
      }}
      className="my-custom-input-class" // a styling class
    />
  );

  return (
    <>
      <Grid
        templateColumns="65px 1fr 1fr 1fr 1fr"
        templateRows="70px 35px auto"
        width="full"
        // @ts-ignore
        align="center"
        height="100%"
        alignSelf="center"
      >
        <GridItem colSpan={4}>
          <SlideFade in={true} offsetY="30px">
            <HStack align="center">
              <Heading
                as="button"
                size="lg"
                m={0}
                color={selectedDay.isToday() ? "black" : "gray.300"}
                onClick={() => setSelectedDay(dayjs.tz())}
              >
                Today
              </Heading>

              <Box w={6}></Box>

              <Heading
                as="button"
                size="lg"
                mb={0}
                pl={12}
                color={selectedDay.isTomorrow() ? "black" : "gray.300"}
                onClick={() => setSelectedDay(dayjs.tz().add(1, "day"))}
              >
                Tomorrow
              </Heading>

              <Spacer onClick={() => setSelectedItemId("")} />

              <Text fontSize={20} pt="2px" pr={6}>
                {plural("job", allJobs.length + directSupportCallList.length, { ifZero: "" })}
              </Text>

              <Heading size="lg">
                <Button
                  bgColor="gray.100"
                  onClick={() => setSelectedDay(selectedDay.add(-1, "day"))}
                >
                  <Text>{"<"}</Text>
                </Button>
                {/* @ts-expect-error old libray that isn't maintained */}
                <DatePicker
                  value={{
                    year: selectedDay.year(),
                    month: selectedDay.month() + 1,
                    day: selectedDay.date(),
                  }}
                  onChange={selectDate}
                  inputPlaceholder="Select a day"
                  shouldHighlightWeekends
                  locale={datepickerLocale}
                  formatInputText={formatInputValue}
                  renderInput={renderCustomInput}
                  colorPrimary="var(--chakra-colors-brand-500)"
                  calendarTodayClassName="custom-today-day"
                />
                <Button
                  bgColor="gray.100"
                  onClick={() => setSelectedDay(selectedDay.add(1, "day"))}
                >
                  <Text>{">"}</Text>
                </Button>
              </Heading>
            </HStack>
          </SlideFade>
        </GridItem>
        <GridItem textAlign="end">
          <HStack spacing={4} justify="flex-end">
            <IconButton
              aria-label="Add new direct support call"
              icon={<PiFirstAidFill />}
              onClick={() => {
                newDirectSupportCallDisclosure.onOpen();
              }}
            />
            <Legend />
          </HStack>
        </GridItem>

        {/* main grid area */}
        <GridItem colSpan={4} rowSpan={2} overflowX="auto" overflowY="hidden">
          {/* grid for header/footer sections of timeslot grid */}
          <Grid templateRows="60px 1fr" height="100%" width="1560px">
            <GridItem>
              <HStack spacing="16px">
                <Box w="75px"></Box>
                {[1, 2, 3, 4, 5].map((techNo) => (
                  <Box minW="260px" key={techNo} mb={3}>
                    <SlideFade in={true} offsetY="30px">
                      <HStack justify="center" align="center">
                        <Badge
                          borderRadius={12}
                          fontSize="md"
                          variant="solid"
                          colorScheme="brand"
                          mt={1}
                          w={6}
                        >
                          {techNo}
                        </Badge>
                        <Avatar
                          position="relative"
                          bgColor={
                            techInfo && techInfo["tech" + techNo + techSuffix] != undefined
                              ? "gray.600"
                              : "gray.300"
                          }
                          color="white"
                          as="button"
                          name={techInfo?.["tech" + techNo + techSuffix]?.name}
                          boxSize={12}
                          onClick={() => {
                            setSelectedTech(techNo);
                            onOpen();
                          }}
                          src={getAvatarUrl(techInfo?.["tech" + techNo + techSuffix])}
                        />

                        <VStack textAlign="start" spacing={1} align="flex-start">
                          <Text fontWeight="bold">
                            {techInfo && techInfo["tech" + techNo + techSuffix]?.name}
                          </Text>
                          <HStack>
                            {techInfo && techInfo["tech" + techNo + techSuffix]?.label && (
                              <VanStockModal
                                date={selectedDay}
                                techNo={techNo}
                                vanName={techInfo?.["tech" + techNo + techSuffix]?.label}
                              />
                            )}
                          </HStack>
                        </VStack>
                      </HStack>
                    </SlideFade>
                  </Box>
                ))}
              </HStack>
            </GridItem>
            <GridItem overflowY="auto" pb={6} sx={{ "::-webkit-scrollbar": { display: "none" } }}>
              <Grid templateColumns="65px repeat(5, 260px) 65px" gap="20px">
                {timeSlots.map((slot) => {
                  return (
                    <React.Fragment key={getTimeSlotId(slot)}>
                      <GridItem onClick={() => setSelectedItemId("")}>
                        <ScheduleDivider timeSlot={slot} />
                        <Box h="50px" id={getTimeSlotId(slot)}>
                          <Text fontWeight="bold" fontSize="md">
                            {slot.format("h.mma")}
                          </Text>
                        </Box>
                      </GridItem>
                      <GridItem w="260px">
                        <ScheduleDivider timeSlot={slot} />
                        {displayJobsForTimeslot(slot, "tech1")}
                      </GridItem>
                      <GridItem w="260px">
                        <ScheduleDivider timeSlot={slot} />
                        {displayJobsForTimeslot(slot, "tech2")}
                      </GridItem>{" "}
                      <GridItem w="260px">
                        <ScheduleDivider timeSlot={slot} />
                        {displayJobsForTimeslot(slot, "tech3")}
                      </GridItem>
                      <GridItem w="260px">
                        <ScheduleDivider timeSlot={slot} />
                        {displayJobsForTimeslot(slot, "tech4")}
                      </GridItem>
                      <GridItem w="260px">
                        <ScheduleDivider timeSlot={slot} />
                        {displayJobsForTimeslot(slot, "tech5")}
                      </GridItem>
                      <GridItem onClick={() => setSelectedItemId("")}>
                        <ScheduleDivider timeSlot={slot} />
                        <Box h="50px">
                          <Text fontWeight="bold" fontSize="md">
                            {slot.format("h.mma")}
                          </Text>
                        </Box>
                      </GridItem>
                    </React.Fragment>
                  );
                })}
              </Grid>
            </GridItem>
          </Grid>
        </GridItem>

        {/* unassigned area */}
        <GridItem borderLeft="solid 1px lightgray">
          {selectedItem && selectedItem.assignedTo ? (
            <Fade in={true}>
              <DropZone handleUpdate={() => unassignSelectedJob()}>
                <Text fontSize="sm" color="brand.500">
                  Unassign
                </Text>
              </DropZone>
            </Fade>
          ) : (
            <Fade in={true}>
              <Badge fontSize="md" variant="outline">
                Unassigned
              </Badge>
            </Fade>
          )}
        </GridItem>
        <GridItem
          borderLeft="solid 1px lightgray"
          overflow="auto"
          sx={{ "::-webkit-scrollbar": { display: "none" } }}
        >
          <VStack spacing="8px" mt={2} ml={4} pb={12}>
            {allJobs
              .filter((jobs) => !jobs.assignedTo)
              .map((job) => (
                <JobCard
                  key={job.id}
                  order={job}
                  type="drive"
                  isSelected={selectedItem?.id == job.id}
                  selectCard={() => selectCard(job, true)}
                />
              ))}
            {directSupportCallList
              .filter((jobs) => !jobs.assignedTo)
              .map((supportCall) => (
                <JobCardDirectSupport
                  key={supportCall.id}
                  isSelected={selectedItemId == supportCall.id}
                  onSelectCard={(item) => selectCard(item, true)}
                  supportCall={supportCall}
                />
              ))}
          </VStack>
        </GridItem>
      </Grid>
      {isOpen && selectedTech && (
        <TechSelectModal isOpen={isOpen} onClose={onClose} date={selectedDay} tech={selectedTech} />
      )}
      {newDirectSupportCallDisclosure.isOpen && (
        <DirectSupportCallModal
          operation="create"
          selfDisclosure={newDirectSupportCallDisclosure}
          deafaultDate={selectedDay}
        />
      )}
    </>
  );
}
