import { useState } from "react";

import { FormLabel, HStack, Select, Switch, VStack, Text } from "@chakra-ui/react";

import { SkierAdjustmentDayPositionSchema } from "@snopro/common/models.js";

import { useShowError } from "@/lib/error.js";
import { updateOrderField, orderApplyDiscountOrDaysAdjusments } from "@/lib/order.api.js";

/**
 *
 * @param {object} props
 * @param {TOrderAndSkiers} props.order
 * @returns {JSX.Element}
 */
export const OrderAdjustments = ({ order }) => {
  const { showError } = useShowError();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isDisabled = isSubmitting || order.state === "completed";
  return (
    <HStack w="100%" justify="flex-end" spacing={6} my={4}>
      <VStack spacing={0} align="start">
        <Text>Order-wide:</Text>
      </VStack>
      <VStack spacing={0} align="start">
        <Select
          isDisabled={isDisabled}
          defaultValue=""
          size="xs"
          onChange={async (e) => {
            if (e.target.value === "") {
              return;
            }
            const dayAdjustment = Number(e.target.value);

            await orderApplyDiscountOrDaysAdjusments({
              order,
              source: "order-page",
              dayAdjustment,
            }).catch(showError);
          }}
        >
          <option key="nothing" value="">
            adjust days
          </option>
          <option key="0" value="0">
            clear adjustments
          </option>
          {new Array(Math.max(order.skiDays, 0)).fill(0).map((_, i) => (
            <option key={i + 1} value={`${-(i + 1)}`}>
              -{i + 1} day
            </option>
          ))}
        </Select>
      </VStack>
      <VStack spacing={0} align="start">
        <Select
          isDisabled={isDisabled}
          defaultValue=""
          size="xs"
          onChange={async (e) => {
            const parsed = SkierAdjustmentDayPositionSchema.safeParse(e.target.value);
            if (!parsed.success) {
              return;
            }
            setIsSubmitting(true);
            await orderApplyDiscountOrDaysAdjusments({
              order,
              source: "order-page",
              dayAdjustmentPosition: parsed.data,
            })
              .catch(showError)
              .finally(() => setIsSubmitting(false));
          }}
        >
          <option value="">position</option>
          <option value="start">at start</option>
          <option value="mid">during</option>
          <option value="end">at end</option>
        </Select>
      </VStack>
      <VStack spacing={0} align="start">
        <Select
          isDisabled={Boolean(order.promoCode?.code) || order.autoCalcDiscount || isDisabled}
          defaultValue=""
          size="xs"
          onChange={async (e) => {
            if (e.target.value === "") {
              return;
            }
            const discountPercentage = Number(e.target.value);
            await orderApplyDiscountOrDaysAdjusments({
              order,
              source: "order-page",
              discountPercentage,
            })
              .catch(showError)
              .finally(() => setIsSubmitting(false));
          }}
        >
          <option value="">discount</option>
          <option value={0}>clear discount</option>
          <option value={0.1}>-10%</option>
          <option value={0.15}>-15%</option>
          <option value={0.2}>-20%</option>
          <option value={0.25}>-25%</option>
          <option value={0.5}>-50%</option>
          <option value={1}>-100%</option>
        </Select>
      </VStack>
      <FormLabel htmlFor="auto-calc-discounts">Auto-calc. discounts</FormLabel>
      <Switch
        id="auto-calc-discounts"
        colorScheme="brand"
        isChecked={order.autoCalcDiscount !== false}
        onChange={async (e) => {
          setIsSubmitting(true),
            await updateOrderField(order, "autoCalcDiscount", e.target.checked, "order-page")
              .catch(showError)
              .finally(() => setIsSubmitting(false));
        }}
        isDisabled={Boolean(order.promoCode?.code)}
        isReadOnly={isSubmitting}
      />
    </HStack>
  );
};
