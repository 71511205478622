import { useEffect, useState } from "react";

import {
  Text,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  HStack,
  SlideFade,
  Box,
} from "@chakra-ui/react";

import { InfoIcon } from "@chakra-ui/icons";
import {
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
  TbSquareRoundedNumber4Filled,
  TbSquareRoundedNumber5Filled,
  TbSquareRoundedNumber1,
  TbSquareRoundedNumber2,
  TbSquareRoundedNumber3,
  TbSquareRoundedNumber4,
  TbSquareRoundedNumber5,
} from "react-icons/tb";

import { db } from "../../../services/firebase.js";

import { doc, onSnapshot } from "firebase/firestore";

import { useAuth } from "../../../contexts/AuthContext.jsx";

export default function FlowStepper({ flowType, steps, activeStep, setActiveStep }) {
  const numberIcons = [
    <TbSquareRoundedNumber1 key="1" />,
    <TbSquareRoundedNumber2 key="2" />,
    <TbSquareRoundedNumber3 key="3" />,
    <TbSquareRoundedNumber4 key="4" />,
    <TbSquareRoundedNumber5 key="5" />,
  ];
  const numberIconsBold = [
    <TbSquareRoundedNumber1Filled key="a1" />,
    <TbSquareRoundedNumber2Filled key="a2" />,
    <TbSquareRoundedNumber3Filled key="a3" />,
    <TbSquareRoundedNumber4Filled key="a4" />,
    <TbSquareRoundedNumber5Filled key="a5" />,
  ];

  const [tips, setTips] = useState(null);
  const { currentUserDetails } = useAuth();

  useEffect(() => {
    if (currentUserDetails?.isTrainingModeEnabled) {
      const unsub = onSnapshot(doc(db, "data", "tips"), (doc) => {
        /** @type {any} */
        const data = doc.data();
        setTips(data);
      });
      return unsub;
    }
  }, []);

  return (
    <>
      <Breadcrumb spacing={4} fontSize="2xl" separator={""} mb={6}>
        {steps.map((step, i) => (
          <BreadcrumbItem key={step.title}>
            <BreadcrumbLink
              onClick={() => setActiveStep(i + 1)}
              _hover={{ textDecoration: "none" }}
              _focus={{ outline: "none" }}
              _activeLink={{ color: "brand.500" }}
            >
              <HStack>
                <>{activeStep == i + 1 ? numberIconsBold[i] : numberIcons[i]}</>
                <Text fontWeight={activeStep == i + 1 ? "bold" : ""}>{step.title}</Text>
              </HStack>
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
      <Box mb={6}>
        {tips && (
          <SlideFade in={true} offsetX={12} offsetY={0}>
            <HStack
              maxWidth="900px"
              alignItems="flex-start"
              mb={3}
              backgroundColor="brand.500"
              paddingX={2}
              paddingY={1}
              borderRadius={8}
            >
              <InfoIcon color="white" mt={1} />
              <Text color="white">{tips[flowType][activeStep]}</Text>
            </HStack>
          </SlideFade>
        )}
      </Box>
    </>
  );
}
