/**
 * @param {TLocation} location
 * @returns {TLocationConfig}
 */
export const getLocationSettings = (location) => {
  return {
    id: location,
    minRentalDays: location === "Hilton" ? 1 : 2,
    openingHours: location === "Hilton" ? 13 : 7,
    closingHours: location === "Hilton" ? 20 : 21,
    hasDelivery: location !== "Hilton",
    inStore: location === "Hilton",
    isPOSEnabled: location === "Hilton",
  };
};

/** @returns {TLocation[]} */
export const getAllLocations = () => ["Queenstown", "Wanaka", "Hilton"];
