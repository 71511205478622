import { db, auth } from "@/services/firebase.js";

import {
  doc,
  arrayUnion,
  deleteField,
  arrayRemove,
  collection,
  increment,
  serverTimestamp,
  writeBatch,
  setDoc,
  FieldValue,
} from "firebase/firestore";

import { SkierSchema, SkierSundriesItemSchema } from "@snopro/common/models.js";
import { z } from "zod";

import { getLatestConfigData } from "./configData.jsx";
import { mapUpdateFirestoreToChangeLog } from "./firebase-utils.js";

/**
 * @param {string} orderId
 * @param {string} skierId
 * @param {Partial<TSkier>|null} skier
 * @param {"update"|"delete"|"add"} changeType
 * @param {object} [updatePayload]
 * @param {import("firebase/firestore").WriteBatch} [batch]
 * @param {TOrderChangeSource} [source]
 * @returns {Promise<void>}
 */
export async function skierChanges(
  orderId,
  skierId,
  skier,
  changeType,
  updatePayload,
  batch,
  source,
) {
  const user = auth.currentUser
    ? { id: auth.currentUser.uid, name: auth.currentUser.displayName ?? "" }
    : undefined;

  /** @type {TOrderChange} */
  const changeData = {
    ...(user && { user }),
    orderId,
    skiers: [
      {
        ...(skier && { skier: skier }),
        id: skierId,
        changeType,
        ...(updatePayload && { updatePayload: mapUpdateFirestoreToChangeLog(updatePayload) }),
      },
    ],
    createdAt: new Date(),
    status: "queued",
  };
  if (source) {
    changeData.source = source;
  }

  const newChangeDoc = doc(collection(db, "orders", orderId, "changes"));
  const setPayload = { ...changeData, createdAt: serverTimestamp() };
  if (batch) {
    batch.set(newChangeDoc, setPayload);
    return;
  }
  await setDoc(newChangeDoc, setPayload);
}
const UpdateSkierSchema = SkierSchema.pick({
  firstName: true,
  lastName: true,
  gender: true,
  ability: true,
  stance: true,
  age: true,
  weight: true,
  height: true,
  shoe: true,
  duplicateOf: true,
  isJunior: true,
  selectedBoot: true,
  ownBoots: true,
  ownBootSole: true,
  hasBootShoeSizeChangedOnDelivery: true,
  selectedHardware: true,
})
  .strip()
  .partial();
/**
 * @typedef {z.infer<typeof UpdateSkierSchema>} TUpdateSkier
 * @typedef {SAS2.firebase.FirestoreFieldPatch<TUpdateSkier>} TUpdateSkierPatch
 */

/**
 * @param {string} orderId
 * @param {string} skierId
 * @param {SAS2.firebase.FirestoreFieldPatch<TUpdateSkier>} data
 * @param {TOrderChangeSource} [source]
 * @param {import("firebase/firestore").WriteBatch} [firestoreBatch]
 * @returns {Promise<void>}
 */
export async function updateSkier(orderId, skierId, data, source, firestoreBatch) {
  const plainData = { ...data };
  const firestoreFields = { ...data };
  for (const key in plainData) {
    if (plainData[key] instanceof FieldValue) {
      delete plainData[key];
    } else {
      delete firestoreFields[key];
    }
  }

  const skierUpdate = { ...UpdateSkierSchema.parse(plainData), ...firestoreFields };
  const skierRef = doc(db, "orders", orderId, "skiers", skierId);

  if (typeof skierUpdate.age === "number") {
    skierUpdate.isJunior = skierUpdate.age <= 4;
  }

  if (source === "flow-deliver" && (skierUpdate.selectedBoot || skierUpdate.shoe)) {
    skierUpdate.hasBootShoeSizeChangedOnDelivery = true;
  }

  const batch = firestoreBatch || writeBatch(db);
  batch.update(skierRef, skierUpdate);
  await skierChanges(orderId, skierId, null, "update", skierUpdate, batch, source);
  if (!firestoreBatch) {
    await batch.commit();
  }
}
const AddSkierPayloadSchema = SkierSchema.pick({
  firstName: true,
  lastName: true,
  gender: true,
  ability: true,
  stance: true,
  age: true,
  weight: true,
  height: true,
  shoe: true,
}).strict();
/**
 * @param {string} orderId
 * @param {z.infer<typeof AddSkierPayloadSchema>} payload
 * @returns {Promise<{id:string}>}
 */
export async function addSkier(orderId, payload) {
  payload = AddSkierPayloadSchema.parse(payload);
  const newSkierDoc = doc(collection(db, "orders", orderId, "skiers"));
  const skier = SkierSchema.omit({ created: true }).parse({
    ...payload,
    id: newSkierDoc.id,
    isJunior: payload.age <= 4,
  });
  const data = {
    ...skier,
    created: serverTimestamp(),
  };
  const batch = writeBatch(db);

  batch.set(newSkierDoc, data);
  batch.update(doc(db, "orders", orderId), { skierCount: increment(1) });
  await skierChanges(orderId, newSkierDoc.id, payload, "add", undefined, batch);

  await batch.commit();

  return { id: newSkierDoc.id };
}

const DuplicateSkierPayloadSchema = SkierSchema.pick({
  firstName: true,
  lastName: true,
  gender: true,
  ability: true,
  stance: true,
  age: true,
  weight: true,
  height: true,
  shoe: true,
  isJunior: true,
}).strip();
/**
 * @param {string} orderId
 * @param {TSkier} originalSkier
 * @returns {Promise<{id:string}>}
 */
export async function duplicateSkier(orderId, originalSkier) {
  const payload = DuplicateSkierPayloadSchema.parse(originalSkier);
  const skierDoc = doc(collection(db, "orders", orderId, "skiers"));
  const skier = SkierSchema.omit({ created: true }).parse({
    ...payload,
    id: skierDoc.id,
    duplicateOf: originalSkier.id,
  });
  const data = { ...skier, created: serverTimestamp() };

  await setDoc(skierDoc, data);
  return { id: skierDoc.id };
}

/**
 * @param {string} orderId
 * @param {TSkier} skier
 * @returns {Promise<void>}
 */
export async function removeSkier(orderId, skier) {
  const batch = writeBatch(db);
  // TODO: move this logic to the cloud function
  batch.delete(doc(db, "allocations", skier.id));
  batch.delete(doc(db, "orders", orderId, "skiers", skier.id));

  // TODO: move this logic to the cloud function
  if (!skier.duplicateOf) {
    batch.update(doc(db, "orders", orderId), { skierCount: increment(-1) });
  }
  await skierChanges(orderId, skier.id, skier, "delete", undefined, batch);
  await batch.commit();
}

/**
 * @param {string} orderId
 * @param {string} skierId
 * @param {keyof TConfigData["misc"]} miscItem
 */
export async function skierAddMiscItem(orderId, skierId, miscItem) {
  const configData = await getLatestConfigData();
  const skierDoc = doc(db, "orders", orderId, "skiers", skierId);
  /** @type {any} */
  const updatePayload = {
    [miscItem]: true,
    [`costs.${miscItem}`]: configData.misc[miscItem].price,
  };

  if (miscItem == "ownBoots") {
    updatePayload.selectedBoot = deleteField();
  }

  const batch = writeBatch(db);
  batch.update(skierDoc, updatePayload);

  await skierChanges(orderId, skierId, null, "update", updatePayload, batch);
  await batch.commit();
}

/**
 * @param {string} orderId
 * @param {string} skierId
 * @param {TSundriesItem} sundriesItem
 */
export async function skierAddSundriesItem(orderId, skierId, sundriesItem) {
  const id = `skier${+new Date()}`;
  const data = SkierSundriesItemSchema.parse({ ...sundriesItem, id, sundriesId: sundriesItem.id });
  const skierDoc = doc(db, "orders", orderId, "skiers", skierId);
  const updateData = { sundries: { [id]: data } };

  const batch = writeBatch(db);
  batch.set(skierDoc, updateData, { mergeFields: [`sundries.${id}`] });
  await skierChanges(orderId, skierId, null, "update", updateData, batch);
  await batch.commit();
}

/**
 *
 * @param {string} orderId
 * @param {string} skierId
 * @param {string} sundriesId
 * @param {SAS2.firebase.FirestoreFieldPatch<TSkierSundriesItem>} payload
 * @returns {Promise<void>}
 */
export async function skierUpdateSundries(orderId, skierId, sundriesId, payload) {
  const update = {};
  Object.entries(payload).forEach(([key, value]) => {
    update[`sundries.${sundriesId}.${key}`] = value;
  });
  const skierDoc = doc(db, "orders", orderId, "skiers", skierId);
  const batch = writeBatch(db);
  batch.update(skierDoc, update);
  await skierChanges(orderId, skierId, null, "update", update, batch);
  await batch.commit();
}

/**
 * @param {string} orderId
 * @param {string} skierId
 * @param {TLocation} locationId
 * @param {string} productKey
 */
export async function skierAddProduct(orderId, skierId, locationId, productKey) {
  const skierDoc = doc(db, "orders", orderId, "skiers", skierId);
  const configData = await getLatestConfigData();
  const product = configData.products?.[locationId]?.[productKey];
  if (!product) {
    throw new Error(`Product not found: ${locationId}/${productKey}`);
  }
  const extra = {
    code: product.code,
    name: product.name,
    price: product.price,
    shortname: product.shortname,
    type: product.type,
  };
  const updatePayload = { extras: arrayUnion(extra) };

  const batch = writeBatch(db);
  batch.update(skierDoc, updatePayload);
  await skierChanges(orderId, skierId, null, "update", updatePayload, batch);
  await batch.commit();
}
/**
 * @param {string} orderId
 * @param {string} skierId
 * @param {TSkierExtra} product
 */
export async function removeSkierProduct(orderId, skierId, product) {
  if (!orderId || !skierId) {
    throw new Error("Missing order or skier id");
  }

  const skierRef = doc(db, "orders", orderId, "skiers", skierId);
  const updatePayload = { extras: arrayRemove(product) };

  const batch = writeBatch(db);
  batch.update(skierRef, updatePayload);
  await skierChanges(orderId, skierId, null, "update", updatePayload, batch);
  await batch.commit();
}

/**
 * @param {string} orderId
 * @param {string} skierId
 * @param {TLocation} locationId
 * @param {TPackageType} packageType
 */
export async function skierAddPackageType(orderId, skierId, locationId, packageType) {
  const skierDoc = doc(db, "orders", orderId, "skiers", skierId);
  const configData = await getLatestConfigData();
  const packageItem = configData.packages?.[locationId]?.[packageType];
  if (!packageItem) {
    throw new Error(`Package not found: ${locationId}/${packageType}`);
  }

  const updatePayload = { packageType, "costs.packageType": packageItem.price };

  const batch = writeBatch(db);
  batch.update(skierDoc, updatePayload);

  await skierChanges(orderId, skierId, null, "update", updatePayload, batch);
  await batch.commit();
}
/**
 * @param {string} orderId
 * @param {string} skierId
 * @param {string} sundriesId
 * @returns {Promise<void>}
 */
export async function removeSundries(orderId, skierId, sundriesId) {
  if (!orderId || !skierId) {
    throw new Error("Missing order or skier id");
  }
  const skierRef = doc(db, "orders", orderId, "skiers", skierId);
  const changes = { [`sundries.${sundriesId}`]: deleteField() };
  const batch = writeBatch(db);
  batch.update(skierRef, changes);
  await skierChanges(orderId, skierId, null, "update", changes, batch);
  await batch.commit();
}

/**
 * @param {string} orderId
 * @param {TSkier} skier
 * @param {TTypeOfCosts} itemType
 * @returns {Promise<void>}
 */
export async function removeSkierItem(orderId, skier, itemType) {
  if (!orderId || !skier) {
    throw new Error("Missing order or skier id");
  }
  const lookup = await getLatestConfigData();
  const skierRef = doc(db, "orders", orderId, "skiers", skier.id);
  const batch = writeBatch(db);

  /** @type {any} */
  let changes = {};
  switch (itemType) {
    case "skiPass":
      changes = {
        [itemType]: deleteField(),
        [`costs.${itemType}`]: 0,
        [`adjustments.${itemType}_days`]: deleteField(),
      };
      break;
    case "packageType":
      changes = {
        packageType: deleteField(),
        selectedHardware: deleteField(),
        [`costs.${itemType}`]: 0,
        "adjustments.packageType_days": deleteField(),
        "adjustments.packageType_pos": deleteField(),
      };
      batch.delete(doc(db, "allocations", skier.id));
      break;
    case "combo":
      changes = {
        pants: false,
        jacket: false,
        "costs.combo": 0,
        "costs.pants": 0,
        "costs.jacket": 0,
        "adjustments.jacket_days": deleteField(),
        "adjustments.pants_days": deleteField(),
        "adjustments.combo_days": deleteField(),
      };
      break;
    case "jacket":
      if (skier.pants) {
        changes["adjustments.combo_days"] = deleteField();
        changes["costs.combo"] = 0;
        changes["costs.pants"] = Number(lookup.items.pants[skier.isJunior ? "junior" : "adult"]);
      }
      changes.jacket = false;
      changes["costs.jacket"] = 0;
      changes["adjustments.jacket_days"] = deleteField();
      break;
    case "pants":
      if (skier.jacket) {
        changes["adjustments.combo_days"] = deleteField();
        changes["costs.combo"] = 0;
        changes["costs.jacket"] = Number(lookup.items.jacket[skier.isJunior ? "junior" : "adult"]);
      }
      changes.pants = false;
      changes["costs.pants"] = 0;
      changes["adjustments.pants_days"] = deleteField();
      break;
    default:
      changes = {
        [itemType]: false,
        [`costs.${itemType}`]: 0,
        [`adjustments.${itemType}_days`]: deleteField(),
      };
      break;
  }

  batch.update(skierRef, changes);
  await skierChanges(orderId, skier.id, null, "update", changes, batch);
  await batch.commit();
}

/**
 * @param {object} props
 * @param {string} props.orderId
 * @param {TSkier} props.skier
 * @param {TTypeOfCosts} props.itemType
 */
export async function addSkierItem({ orderId, skier, itemType }) {
  const lookup = await getLatestConfigData();
  const skierDoc = doc(db, "orders", orderId, "skiers", skier.id);
  let changes = {};
  if (
    itemType == "combo" ||
    (itemType == "jacket" && skier.pants) ||
    (itemType == "pants" && skier.jacket)
  ) {
    changes = {
      jacket: true,
      pants: true,
      "costs.combo": Number(lookup.items.combo[skier.isJunior ? "junior" : "adult"]),
      "costs.pants": 0,
      "costs.jacket": 0,
    };
  } else {
    changes = {
      [itemType]: true,
      [`costs.${itemType}`]: Number(lookup.items[itemType][skier.isJunior ? "junior" : "adult"]),
    };
  }
  const batch = writeBatch(db);
  batch.update(skierDoc, changes);
  await skierChanges(orderId, skier.id, null, "update", changes, batch);
  await batch.commit();
}

/**
 * @param {string} orderId
 * @param {string} skierId
 * @param {TTypeOfDiscounts} discountType
 * @param {number} discountValue
 */
export async function updateSkierDiscount(orderId, skierId, discountType, discountValue) {
  const skierDoc = doc(db, "orders", orderId, "skiers", skierId);
  const changes = { [`discountsPercentage.${discountType}`]: discountValue };

  const batch = writeBatch(db);
  batch.update(skierDoc, changes);

  await skierChanges(orderId, skierId, null, "update", changes, batch);
  await batch.commit();
}
