import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  Container,
  Divider,
  Fade,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useDisclosure,
  Text,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";

import { db } from "@/services/firebase.js";

import { deleteField, doc, serverTimestamp, writeBatch } from "firebase/firestore";

import { getFieldAsDate } from "@snopro/common/firestore.js";
import { asPrice } from "@snopro/common/numbers.js";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";

import { useLoggedInUser } from "@/contexts/AuthContext.jsx";
import { registerOrderIdHistory } from "@/contexts/OrderModalContext.jsx";
import { useFetchOrderWithSkiers } from "@/hooks/orderHooks.js";
import { useShowError } from "@/lib/error.js";
import { initRefButton, initStringArray } from "@/lib/initialize-states.js";
import { useLocationConfig } from "@/lib/locationLib.js";
import { orderCompleteDelivery, updateOrder, updateOrderField } from "@/lib/order.api.js";
import { registerSystemAlertUnpaidOrderCollected } from "@/lib/systemAlertLib.js";
import { registerActivitySteFlow } from "@/services/activityLog.js";

import FlowStepper from "../common/FlowStepper.jsx";
import StepCollectInfo from "./StepCollectInfo.jsx";
import StepCollectItems from "./StepCollectItems.jsx";
import StepDeliverCustomerSummary from "./StepDeliverCustomerSummary.jsx";
import StepDeliverDriverSummary from "./StepDeliverDriverSummary.jsx";
import StepDeliverFitSkiers from "./StepDeliverFitSkiers.jsx";
import StepDeliverReview from "./StepDeliverReview.jsx";
import StepDeliverSkiPasses from "./StepDeliverSkiPasses.jsx";
import StepPackBagTag from "./StepPackBagTag.jsx";
import StepPackBoots from "./StepPackBoots.jsx";
import StepPackExtras from "./StepPackExtras.jsx";
import StepPackHardware from "./StepPackHardware.jsx";
import StepPackOuterwear from "./StepPackOuterwear.jsx";
import StepSizeAllocate from "./StepSizeAllocate.jsx";
import StepSizeCheckOrder from "./StepSizeCheckOrder.jsx";

/** @type {Record<TStepFlowType, { title:string; component: any }[]>} */
const stepDataDelivery = {
  size: [
    {
      title: "Check Info",
      component: StepSizeCheckOrder,
    },
    { title: "Confirm Hardware", component: StepSizeAllocate },
  ],
  pack: [
    {
      title: "Get Boots",
      component: StepPackBoots,
    },
    {
      title: "Set Hardware",
      component: StepPackHardware,
    },
    {
      title: "Get Outerwear",
      component: StepPackOuterwear,
    },
    {
      title: "Get Extras",
      component: StepPackExtras,
    },
    {
      title: "Bag Tag",
      component: StepPackBagTag,
    },
  ],
  deliver: [
    {
      title: "Info",
      component: StepDeliverReview,
    },
    {
      title: "Fit Skiers",
      component: StepDeliverFitSkiers,
    },
    {
      title: "Ski Passes",
      component: StepDeliverSkiPasses,
    },
    {
      title: "Sign-off",
      component: StepDeliverCustomerSummary,
    },
    {
      title: "Driver Summary",
      component: StepDeliverDriverSummary,
    },
  ],
  collect: [
    { title: "Info", component: StepCollectInfo },
    { title: "Items for Collection", component: StepCollectItems },
  ],
};
/** @type {Record<Exclude<TStepFlowType,"pack">, { title:string; component: any }[]>} */
const stepDataInstore = {
  size: [
    {
      title: "Check Info",
      component: StepSizeCheckOrder,
    },
    { title: "Confirm Hardware", component: StepSizeAllocate },
  ],
  deliver: [
    {
      title: "Fit Skiers",
      component: StepDeliverFitSkiers,
    },

    {
      title: "Set Hardware",
      component: StepPackHardware,
    },
    {
      title: "Ski Passes",
      component: StepDeliverSkiPasses,
    },
    {
      title: "Sign-off",
      component: StepDeliverCustomerSummary,
    },
    {
      title: "Fitting Summary",
      component: StepDeliverDriverSummary,
    },
  ],
  collect: [
    { title: "Info", component: StepCollectInfo },
    { title: "Items for Return", component: StepCollectItems },
  ],
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

/**
 * @param {object} props
 * @param {TStepFlowType} props.flowType
 * @returns {JSX.Element|null}
 */
export default function StepFlow({ flowType }) {
  const { showError } = useShowError();
  const { orderId } = useParams();
  const [activeStep, setActiveStep] = useState(1);
  const prevOrderId = usePrevious(orderId);

  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(initRefButton());
  const { currentUserDetails } = useLoggedInUser();
  const [isSaving, setIsSaving] = useState(false);
  const warningMessageDisclosure = useDisclosure();

  const { data: calculatedOrder } = useFetchOrderWithSkiers({ orderId });
  const order = calculatedOrder?.order;
  const { inStore } = useLocationConfig(order?.location);
  const stepData = inStore ? stepDataInstore : stepDataDelivery;

  const [warningMessage, setWarningMessage] = useState("");
  const [orderIdWarning, setOrderIdWarning] = useState(initStringArray());
  /** @param {string} message */
  const showWarningMessage = useCallback(
    (message) => {
      if (!message || !orderId || orderIdWarning.includes(orderId)) {
        return;
      }
      setOrderIdWarning((prev) => [...prev, orderId]);
      setWarningMessage(message);
      if (warningMessageDisclosure.isOpen) {
        return;
      }
      warningMessageDisclosure.onOpen();
    },
    [orderIdWarning, orderId],
  );

  useEffect(() => {
    if (prevOrderId != orderId) {
      setActiveStep(1); //reset to step 1 if showing a different order
    }
    registerOrderIdHistory(orderId ?? "");
  }, [orderId, prevOrderId]);

  //on component unload, clear activeUser
  useEffect(() => {
    return () => {
      if (orderId) {
        updateOrderField({ id: orderId }, "activeUser", null, `flow-${flowType}`);
      }
    };
  }, [orderId, flowType]);
  const collectTime = useMemo(
    () => (order?.collectTime ? getFieldAsDate(order.collectTime).getTime() : undefined),
    [order],
  );

  useEffect(() => {
    if (!collectTime || flowType !== "collect" || dayjs.tz(collectTime).isSame(dayjs.tz(), "day")) {
      return;
    }

    showWarningMessage(
      `Not due for collection until ${dayjs.tz(collectTime).format("DD/MM/YYYY")} are you sure?`,
    );
  }, [flowType, collectTime, showWarningMessage]);
  const { completionMessage, completionMessageLevel } = useMemo(() => {
    if (!order || flowType !== "collect" || order.balance <= 0) {
      return {
        completionMessage: `Are you sure this job has been completed?`,
        completionMessageLevel: undefined,
      };
    }

    return {
      completionMessage: `Outstsanding balance of ${asPrice(order.balance)}! Are you sure ?`,
      completionMessageLevel: "warn",
    };
  }, [order, flowType]);

  function ClickNextButton() {
    if (activeStep < stepData[flowType].length) {
      setActiveStep(activeStep + 1);
    } else {
      onOpen();
    }
  }

  function exitFlow() {
    navigate(-1);
  }

  async function completeJob() {
    if (!order) return;
    try {
      if (order.state === "inactive") {
        throw new Error("Can't change, booking is inactive!");
      }
      setIsSaving(true);
      const firestoreBatch = writeBatch(db);
      const userInfo = {
        id: currentUserDetails.id,
        firstName: currentUserDetails.firstName,
        lastName: currentUserDetails.lastName,
      };
      /** @type {TOrderState} */
      let nextState;
      /** @type {SAS2.firebase.FirestoreFieldPatch<TOrder["stepFlows"]>} */
      let stepFlowData;
      switch (flowType) {
        case "size":
          stepFlowData = { sized: { timestamp: serverTimestamp(), user: userInfo } };
          if (inStore) {
            nextState = "toDeliver";
          } else {
            nextState = "toPack";
          }
          break;
        case "pack":
          stepFlowData = { packed: { timestamp: serverTimestamp(), user: userInfo } };
          nextState = "toDeliver";
          break;
        case "deliver":
          stepFlowData = { delivered: { timestamp: serverTimestamp(), user: userInfo } };
          nextState = "toCollect";
          break;
        case "collect":
          stepFlowData = { collected: { timestamp: serverTimestamp(), user: userInfo } };
          nextState = "completed";
          if (order.balance > 0) {
            await registerSystemAlertUnpaidOrderCollected(
              order,
              currentUserDetails,
              `flow-${flowType}`,
              firestoreBatch,
            );
          }
          //save hardware to be tuned
          order.skiers.forEach((skier) => {
            if (skier.selectedHardware?.id) {
              firestoreBatch.update(doc(db, "hardware", skier.selectedHardware.id), {
                status: "Tune",
                lastSkiDays: order.skiDays,
                lastSkierName: skier.firstName + " " + skier.lastName,
                lastSkierInsurance: skier?.insurance || false,
                lastOrderId: order.id,
                lastOrderLocation: order.team,
              });
            }
          });
          break;
        default:
          throw new Error(`Unknown flow type ${flowType}`);
      }
      const stepFlowUpdate = Object.entries(stepFlowData ?? {}).reduce((acc, [key, value]) => {
        acc[`stepFlows.${key}`] = value;
        return acc;
      }, {});

      await registerActivitySteFlow(flowType, order, firestoreBatch);
      /** @type {SAS2.firebase.FirestoreFieldPatch<TOrder>} */
      const updatePayload = {
        state: nextState,
        ...stepFlowUpdate,
        ...(nextState == "toCollect"
          ? { assignedTo: deleteField(), driveTime: order.collectTime }
          : {}),
      };
      await updateOrder(order, updatePayload, `flow-${flowType}`, firestoreBatch);

      await firestoreBatch.commit();
      if (nextState == "toCollect") {
        await orderCompleteDelivery({ orderId: order.id });
      }

      navigate(["deliver", "collect", "support"].includes(flowType) ? "/drive" : "/" + flowType);
    } catch (error) {
      showError(error);
    } finally {
      setIsSaving(false);
    }
  }

  function ShowStep(step) {
    const StepComponent = stepData[flowType]?.[step - 1]?.component;
    return (
      calculatedOrder &&
      order &&
      StepComponent && <StepComponent order={order} calculatedOrder={calculatedOrder} />
    );
  }

  if (!order) return null;

  return (
    <>
      <Fade in={true}>
        <Container centerContent mt={6} mb={24} maxWidth="1200px">
          <FlowStepper
            flowType={flowType}
            steps={stepData[flowType]}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
          {ShowStep(activeStep)}
        </Container>
        <Flex
          bottom={4}
          position="fixed"
          justify="space-around"
          width="100%"
          mb={6}
          overflow="auto"
        >
          <Button onClick={() => exitFlow()} size="lg">
            Exit
          </Button>
          <HStack spacing={6}>
            {inStore && flowType == "deliver" && activeStep == 5 && (
              <Button colorScheme="brand" onClick={() => exitFlow()} size="lg">
                Finish later
              </Button>
            )}
            <Button colorScheme="brand" onClick={() => ClickNextButton()} size="lg">
              {activeStep < stepData[flowType].length ? (
                <>Next</>
              ) : inStore && flowType == "deliver" ? (
                <>Finish Now</>
              ) : (
                <>Finish</>
              )}
            </Button>
          </HStack>
        </Flex>
      </Fade>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered={true}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            {completionMessageLevel == "warn" ? (
              <Alert my={12} status="warning">
                <AlertIcon />
                {completionMessage}
              </Alert>
            ) : (
              <AlertDialogBody my={12} justifyContent={"center"} display={"flex"}>
                {completionMessage}
              </AlertDialogBody>
            )}
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Spacer />
              <Button isLoading={isSaving} colorScheme="brand" onClick={completeJob} ml={3}>
                Yes, Job completed by {currentUserDetails.firstName}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal
        isOpen={Boolean(warningMessage && warningMessageDisclosure.isOpen)}
        onClose={warningMessageDisclosure.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Warning</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Divider />
            <Text pt={4} fontSize={"large"}>
              {warningMessage}
            </Text>
            <Divider />
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button size="sm" onClick={warningMessageDisclosure.onClose}>
              Continue
            </Button>
            <Spacer />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
