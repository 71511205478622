import { getLocationSettings, getAllLocations } from "@snopro/common/locations.js";

/**
 * @returns {TLocation[]}
 */
export const useLocations = () => {
  return getAllLocations();
};

export const useLocationsSettings = () => {
  return getAllLocations().map((location) => getLocationSettings(location));
}

/**
 * @param {TLocation} [locationId]
 */
export const useLocationConfig = (locationId) => {
  return getLocationSettings(locationId ?? "Queenstown");
};
